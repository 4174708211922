import { Box, Button, Card, CardContent, Dialog, Stack, Typography , TextField} from "@mui/material";
import { useSnackbar } from "notistack";
import * as Yup from "yup"
import { useFormik, Form, FormikProvider, getIn } from "formik"
import { CustomDatePicker } from "../form/datePicker";
import React, { useEffect, useState } from "react";
import transactionServices from "../../services/transactionServices";
import { convertToIndianFormat } from "../../utils/functions";
import { DividerComponent } from "../miniComponents";
import CloseIcon  from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { LoadingButton } from "@mui/lab";

// import dayjs from 'dayjs';
// import { AccInformation } from "../../pages/marketPlace/property/investPage";


export default function ConfirmaTransaction({open,handleClose=()=>{},data, amount, amtTobePaid, amtPaid,commitmentDate}){
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    useEffect(()=>{
        resetForm();
    },[open])

        const transactionInfoSchema = Yup.object().shape({
            dateoftrans: Yup.date().required("Date is required"),
            chequeno: Yup.string().required("Cheque No or Transaction Number is Required"),
            amountTransferred: Yup.number()
                .min(1, "Enter a valid transfer amount above 0")
                .max(Number(amount - amtPaid), "Enter a valid transfer amount below " + Number(amount-amtPaid))
                .required("Enter a Valid Transferred Amount in INR"),
            });
        const formik = useFormik({
            initialValues:{
                chequeno:'',
                dateoftrans:moment(),
                amountTransferred: Number(amtTobePaid),
            },
            // enableReinitialize: true,
            validationSchema: transactionInfoSchema,
            onSubmit: async(v) => {
                
                setSubmitting(false)
                if(data){
                    data = {
                        amount,
                        banktransferInfo:
                        {chequeno:values.chequeno,dateoftrans:values.dateoftrans.unix(),amountTransferred:values.amountTransferred},
                        ...data
                    }
                    const req = await transactionServices.confirmPayment(data);
                    if(req && req.success){
                        enqueueSnackbar("Success",{variant:"success"});
                        handleClose();
                        resetForm();
                        navigate("/transactions")
                    }
                    else{
                        if(req.data){
                            enqueueSnackbar(req.data.message,{variant:"error"});
                        }
                        else{
                            enqueueSnackbar("Something went wrong!!",{variant:"error"});
                        }
                    }
                }
            },
        })

        const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue, resetForm,setSubmitting} = formik
    
    return(
        <Dialog
        scroll={"body"}
        PaperProps={{sx:{overflow:"auto",maxWidth:470}}}
        open={open}
        onClose={handleClose}
            >
                <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate >
                <Card>
                    <CardContent sx={{paddingTop:{md:"20px"}}}>
                        <Stack direction={"row"} justifyContent={"space-between"}>
                            <Typography variant="body3" sx={{fontWeight:500}}>Confirm Transaction</Typography>
                            <CloseIcon onClick={handleClose} sx={{width:24,height:24,color:"text.disabled",cursor:"pointer"}} />
                        </Stack>
                        <Stack mt={2} spacing={3}>
                            <Box>
                                <DividerComponent />
                            </Box>
                            <Stack spacing={"10px"}>
                                <Typography variant="body1" sx={{fontWeight:500}}>Did you transfer  {convertToIndianFormat(Number(amtTobePaid))} to the required bank account?</Typography>
                                {/* <AccInformation/> */}
                                 <CustomDatePicker label={"Date of Transaction *"}
                                        minDate={moment(commitmentDate)}
                                        disableFuture={true}
                                        value= {values.dateoftrans}
                                        onChange={ (newValue) => {
                                            console.log(newValue);
                                            setFieldValue("dateoftrans", newValue)}
                                        }
                                    
                                />
                                <TextField  size="small" type="text" 
                                label="Cheque No/Transaction Number *"
                                    {...getFieldProps("chequeno")}
                                    error={Boolean(touched.chequeno && errors.chequeno)}
                                    helperText={touched.chequeno && errors.chequeno}
                                />
                                <TextField  size="small" type="number" 
                                label="Amount Transferred *"
                                    {...getFieldProps("amountTransferred")}
                                    onWheel={(event) => event.target.blur()}
                                    error={Boolean(touched.amountTransferred && errors.amountTransferred)}
                                    helperText={touched.amountTransferred && errors.amountTransferred}
                                />
                                <Typography variant="body2" >RealX will verify the payment and the FRAX will be credited to your account.</Typography>
                            </Stack>
                            <Box>
                                <DividerComponent />
                            </Box>
                            <Box>
                                <Box >
                                    {/* <Button onClick={handleSubmit} fullWidth variant="contained" >Confirm Transaction</Button> */}
                                    <Button type="submit"  fullWidth variant="contained" >Confirm Transaction</Button>
                                </Box>
                            </Box>
                        </Stack>
                    </CardContent>
                </Card>
                 </Form>
       </FormikProvider>
        </Dialog>
    )
}
