import { Box, Button, Stack, Typography, Grid, CircularProgress, Backdrop, FormControlLabel, Radio, FormControl, FormLabel, RadioGroup, Divider, ToggleButton, ToggleButtonGroup, keyframes } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import { LoadingButton } from "@mui/lab"
import React, { useEffect, useRef, useState } from "react";
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from "formik";
import Lottie from "lottie-react";
// import { FormDatePicker } from "../form/datePicker";
// import { Box, Button, Stack, Typography, CircularProgress } from "@mui/material";
// import { LoadingButton } from "@mui/lab";
// import React, { useState } from "react";
// import * as Yup from "yup";
// import { useFormik, Form, FormikProvider } from "formik";
// import { useSnackbar } from "notistack";
import moment from "moment";
import FormField from "../../components/form/formField";
import FormSelect from "../../components/form/formSelect";
import familymember from "../../services/familymemberServices";
import { saveUser } from "../../redux/reducers/userSlice";
import { useDispatch } from "react-redux";
import { FormDatePicker } from "../../components/form/datePicker";
import familymemberServices from "../../services/familymemberServices";

const VALID_FILE_SIZE = 5000000;
const SUPPORTED_FORMATS = ["image/png", "image/jpeg", "application/pdf"];
export const AutoKYCForm = ({  setStep, user, enqueueSnackbar, dispatch, handleClose, setAnimating, otp, setOtp, otpForm, refId, setRefId, setOtpForm, aadhaar, setAadhaar, openProgressBar, setOpenProgressBar,setIsManual,setUser ,familymemberId,currentStep ,getallFamilyMember}) => {
   
    const[panAvailabel,setPanAvailabel]=useState(false)
    const kycSchema = Yup.object().shape({
        panCardNumber: Yup.string()
        .when('panAvailabel', {
            is: true, 
            then: Yup.string()
                .matches(/^[A-Za-z]{5}[0-9]{4}[A-Za-z]$/, "Invalid PAN number format")
                .required("PAN No. is required."),
            otherwise: Yup.string().notRequired(),
        }),            
    })
    const [isAdharVerified, setAdharVerified] = useState(false);
    const [isShowOPT, setOTPSection] = useState(false);
    const [validation, setValidation] = React.useState(kycSchema);
   

    const submitAadhaar = async () => {
        const finalAadhaarNumber = aadhaarValues.join("");
        if (finalAadhaarNumber.length !== 12) {
            setOTPSection(false);
            enqueueSnackbar("Invalid Aadhaar Number", { variant: "error", anchorOrigin: { horizontal: "right", vertical: "top" }, autoHideDuration: 5000 })
            return;
        } else {
            setAadhaar(finalAadhaarNumber);
        }

        // Your logic to handle submission
        setOpenProgressBar(true);

        const res = await familymember.familymemberAddharCard({ aadharNo: finalAadhaarNumber });
        setOpenProgressBar(false);
        if (res && res.success) {
            setRefId(res.data.reference_id);
            setOTPSection(true);
            enqueueSnackbar("Aadhaar Number submitted successfully", { variant: "success", anchorOrigin: { horizontal: "right", vertical: "top" }, autoHideDuration: 800 })
        }
        else {
            setOTPSection(false);
            enqueueSnackbar("Aadhar Number Not Found!", { variant: "error", anchorOrigin: { horizontal: "right", vertical: "top" }, autoHideDuration: 5000 })
        }
    }

    const verifyAadhaar = async () => {
        setOpenProgressBar(true);
        const finalAadhaarNumber = aadhaarValues.join("");
        const res = await familymemberServices.familymemberAddharCardVerification({ aadhaar_otp: otp, reference_id: refId, familyMemberId: familymemberId, aadharNo: finalAadhaarNumber })
        setOpenProgressBar(false);
        if (res && res.success) {
            setUser(res.data)
            setAdharVerified(true);
            getallFamilyMember()
            enqueueSnackbar("OTP Verified Successfully", { variant: "success", anchorOrigin: { horizontal: "right", vertical: "top" }, autoHideDuration: 800 })
        }
        else {
            setAdharVerified(false);
            enqueueSnackbar("Invalid OTP, Please check again!", { variant: "error", anchorOrigin: { horizontal: "right", vertical: "top" }, autoHideDuration: 4000 })
        }
    }

    const formik = useFormik({
        initialValues: {
            panCardNumber: "",
            isPanCard:true,
        },
        validationSchema: validation,
        onSubmit: (v) => {
            submitKyc(v);
        },
    });

    const submitKyc = async (data) => {
        const fData = {
            familyMemberId:familymemberId ,
            isPanCard: panAvailabel,
            panCardNumber: data.panCardNumber,
        }
        setSubmitting(true);
        setOpenProgressBar(true)
        const res = await familymemberServices.familymemberCompleteKyc(fData);
        setSubmitting(false);
        setOpenProgressBar(false)
        if (res && res.success) {
            enqueueSnackbar("KYC details submitted", { variant: "success" });
            setAnimating(true)
            setStep(currentStep + 1);
            getallFamilyMember()
            // setUser(res.data)
        }
        else{
            enqueueSnackbar(res.data?.message || "Enter Correct Details", { variant: "error" });
        }
    }

    const { errors, touched, handleSubmit, getFieldProps, values, setFieldValue, setFieldError, handleBlur, isSubmitting, setSubmitting } = formik;
    useEffect(() => {
        if (values.addressProof?.back) {
            let temp = validation.shape({
                addressProofBack: Yup.mixed().nullable().required("Please upload this document.").test("FILE_SIZE", "Upload a file below 5MB.", value => value && value.size <= VALID_FILE_SIZE)
                    .test("FILE_FORMAT", "Uploaded file has unsupported format.", value => value && SUPPORTED_FORMATS.includes(value.type)),
            })
            setValidation(temp);
        }
        else {
            setValidation(kycSchema);
        }
    }, [values.addressProof, validation])


//    useEffect(()=>{
//     if (user?.aadharAddress) {
//         setAdharVerified(true)
//     }
//    },[user])

   

    const aadhaarRefs = [useRef(), useRef(), useRef()]; // Create refs for each box
    const [aadhaarValues, setAadhaarValues] = useState(["", "", ""]);
    const [aadhaarErrors, setAadhaarErrors] = useState([false, false, false]);

    const handleAadhaarChange = (index, value) => {
        if (/^\d*$/.test(value) && value.length <= 4) {
            setAadhaarValues((prevValues) => {
                const updatedValues = [...prevValues];
                updatedValues[index] = value;
                return updatedValues;
            });

            setAadhaarErrors((prevErrors) => {
                const updatedErrors = [...prevErrors];
                updatedErrors[index] = false;
                return updatedErrors;
            });

            if (value.length === 4 && index < 2) {
                aadhaarRefs[index + 1].current.focus();
            }
        } else {
            setAadhaarErrors((prevErrors) => {
                const updatedErrors = [...prevErrors];
                updatedErrors[index] = true;
                return updatedErrors;
            });
        }
    };


    return (
        <Box mt={4}>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                        <Typography variant="h4">Enter Your Document Details</Typography>
                        <Button variant="outlined" style={{ mt: 3 }} 
                        onClick={() => setIsManual(true)}
                        >Switch to Manual KYC</Button>
                    </Stack>
                    <Stack spacing={1.5} mt={3} alignItems={'center'}>
                        <Stack
                            direction={{ md: "column", xs: "column" }}
                            spacing={{ md: 1, xs: 1.5 }}
                        >

                            {!isAdharVerified && <Stack mt={3}>
                                <Typography variant="label">Aadhaar Card No. *</Typography>
                                <Stack direction="row" alignItems="center" spacing={{ sm: 2, xs: 1 }} mt={1} className="adharNoWrapper">
                                    {[0, 1, 2].map((index) => (
                                        <Box key={index}>
                                            <FormField
                                                inputProps={{
                                                    value: aadhaarValues[index],
                                                    onChange: (e) => {
                                                        handleAadhaarChange(index, e.target.value);
                                                    },
                                                    onKeyDown: (e) => {
                                                        if (e.key === "Backspace" && index > 0 && !e.target.value) {
                                                            aadhaarRefs[index - 1].current.focus();
                                                        } else if (e.key >= "0" && e.key <= "9" && index < 2 && e.target.value.length === 4) {
                                                            aadhaarRefs[index + 1].current.focus();
                                                        }
                                                    },
                                                    inputRef: aadhaarRefs[index],
                                                    placeholder: "XXXX",
                                                    inputMode: "numeric", // Enforce numeric keyboard on mobile
                                                    error: aadhaarErrors[index] || (Boolean(touched[`aadhaar${index}`] && errors[`aadhaar${index}`])),
                                                    helperText: aadhaarErrors[index] ? "" : (touched[`aadhaar${index}`] && errors[`aadhaar${index}`]),
                                                    sx: {
                                                        '& .MuiInputBase-root': { minWidth: 0 },
                                                        maxWidth: { lg: "150px !important", md: "110px !important" },
                                                        '& .MuiInputBase-input': { textAlign: 'center' },
                                                        '& .MuiOutlinedInput-root': { padding: { xs: "12px 10px !important" } }
                                                    }
                                                }}

                                            />
                                        </Box>
                                    ))}

                                    <Button
                                        onClick={submitAadhaar}
                                        sx={{ marginTop: "8px !important" }}
                                        variant="contained"
                                        disabled={isAdharVerified}
                                    >
                                        Submit
                                    </Button>
                                </Stack>
                            </Stack>}


                            <Stack item md={5} gap={0}>
                                <Stack>
                                    {isShowOPT && !isAdharVerified &&
                                        <OTPForm otp={otp} setOtp={setOtp} setOtpForm={setOtpForm} verifyAadhaar={verifyAadhaar} />

                                    }
                                </Stack>
                                <Stack mt={{ sm: 3, xs: 1 }}>
                                    {isAdharVerified &&
                                        <Grid>
                                            <Stack
                                                direction={{ md: "row", xs: "column" }}
                                                spacing={{ lg: 2, md: 2, xs: 2 }}
                                                mt={{ sm: 4, xs: 2 }}
                                            >
                                                <input type="checkbox"  onClick={()=>setPanAvailabel(!panAvailabel)}/>
                                                <label for="vehicle1">Do You have a Pancard</label>
                                                

                                            </Stack>
                                            {panAvailabel&&<><Typography variant="label">PAN Card No. *</Typography>
                                            <Stack sx={{ ml: 0 }}>
                                                <FormField
                                                    inputProps={{
                                                        placeholder: "",
                                                        ...getFieldProps("panCardNumber"),
                                                        error: Boolean(
                                                            touched.panCardNumber && errors.panCardNumber
                                                        ),
                                                        helperText: touched.panCardNumber && errors.panCardNumber,
                                                        sx: {
                                                            '& .MuiInputBase-root': { minWidth: 0 },
                                                            maxWidth: { lg: "290px !important", md: "231px !important" }
                                                        }
                                                    }}
                                                />
                                            </Stack></>}

                                            

                                        </Grid>
                                    }
                                </Stack>
                            </Stack>

                        </Stack>
                        {/* <Stack
                direction={{ md: "row", xs: "column" }}
                spacing={{ md: 3, xs: 1.5 }}
              >
                <FormAutoCompleted
                  label={"Address Proof Document *"}
                  options={addressProofOptions}
                  props={{
                    value: values.addressProof,
                    onChange: (e, value) => {
                      setFieldValue("addressProof", value);
                    },
                    options: addressProofOptions,
                    error: Boolean(touched.addressProof && errors.addressProof),
                    helperText: touched.addressProof && errors.addressProof,
                  }}
                ></FormAutoCompleted>
              </Stack> */}

                        <Stack
                            direction={{ md: "row", xs: "column" }}
                            spacing={{ md: 3, xs: 1.5 }}
                        >
                            <Grid item md={6}>
                                {/* <FormFileField
                    label={"Upload Front Side *"}
                    inputProps={{
                      error: Boolean(
                        touched.addressProofFront && errors.addressProofFront
                      ),
                      helperText:
                        touched.addressProofFront && errors.addressProofFront,
                      value: values.addressProofFront,
                      onChange: (file) => {
                        setFieldValue("addressProofFront", file);
                        handleImageChange(file, "frontImage");
                      },
                    }}
                  /> */}
                                {/* Display the selected image in a square container */}

                                {/* {frontSelectedImage && (
                    <div
                      style={{
                        width: "auto",
                        height: "130px",
                        border: "1px solid rgb(183 183 183)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "6px",
                        marginTop: "8px",
                      }}
                    >
                        <img
                          src={frontSelectedImage}
                          alt="Selected Image"
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            objectFit: "cover",
                          }}
                        />
                    </div>
                  )} */}
                            </Grid>

                            <Grid item md={6}>
                                {/* {values.addressProof?.back &&
                    <FormFileField
                      label={"Upload Back Side *"}
                      inputProps={{
                        error: Boolean(
                          touched.addressProofBack && errors.addressProofBack
                        ),
                        helperText:
                          touched.addressProofBack && errors.addressProofBack,
                        value: values.addressProofBack,
                        onChange: (file) =>{
                            setFieldValue("addressProofBack", file);
                            handleImageChange(file, "backImage");
                        },
                      }}
                    />} */}
                                {/* 
{backSelectedImage &&  values.addressProof?.back && 
                    <div
                      style={{
                        width: "auto",
                        height: "130px",
                        border: "1px solid rgb(183 183 183)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "6px",
                        marginTop: "8px",
                      }}
                    >
                        <img
                          src={backSelectedImage}
                          alt="Selected Image"
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            objectFit: "cover",
                          }}
                        />
                    </div>
                  } */}

                            </Grid>
                        </Stack>
                    </Stack>

                    <Stack alignItems={'center'}>
                        {isAdharVerified &&

                            <Grid>
                                {/* <Typography mt={5} variant="h4">
              Enter Bank Account Information
            </Typography> */}

                                <LoadingButton
                                    loading={isSubmitting}
                                    type="submit"
                                    variant="contained"
                                    sx={{ mt: { sm: 3, xs: 2 }, width: { md: "auto", xs: "100%" } }}
                                    loadingIndicator={
                                        <CircularProgress size={30} sx={{ color: 'red' }} />
                                    }
                                >
                                    Submit
                                </LoadingButton>
                            </Grid>
                        }
                    </Stack>

                </Form>
            </FormikProvider>
        </Box>
    );
}

const OTPForm = ({ otp, setOtp, setOtpForm, verifyAadhaar }) => {

    return (
        <Stack alignItems={'center'}>
            <Stack direction={"row"} mt={1} justifyContent={"space-between"}>
                <Typography variant="body2" sx={{ fontSize: 12 }}>OTP Sent To Your Registered Mobile Number</Typography>
            </Stack>
            <Stack spacing={1.5} mt={3}>
                <FormField label={"Enter OTP *"} inputProps={{
                    placeholder: "_ _ _ _ _ _",
                    type: "number",
                    value: otp,
                    onChange: (e) => {
                        if (RegExp('^[0-9]*$').test(e.target.value) && e.target.value?.length < 7) {
                            setOtp(e.target.value)
                        }

                    },
                    sx: { '& .MuiInputBase-root': { minWidth: 0, padding: "11px auto !important", textAlign: 'center' }, maxWidth: "250px !important", '& .MuiInputBase-input': { textAlign: 'center' } }
                }} />
            </Stack>
            {/* <Stack direction={"row"} mt={2} spacing={1}>
                <Typography variant="body2" >Haven't received OTP?</Typography>
                <Typography variant="body2" sx={{ color: "primary.main", cursor: "pointer" }}>Resend OTP</Typography>
            </Stack> */}
            <Stack alignItems={"center"} sx={{ width: '250px' }}>
                <Button onClick={verifyAadhaar} disabled={otp.length !== 6} variant="contained" sx={{ mt: 3 }}  >Verify OTP</Button>
            </Stack>
        </Stack>
    )
}