import React, { useEffect, useState } from 'react';
import ThemeProvider from './theme';
import { Provider } from "react-redux";
import Router from './routes';
import store from './redux/store';
import { SnackbarProvider } from "notistack";
import CustomSnackbar from './components/messageBox/customSnackbar';
import ScrollToTop from './components/scrollToTop';
import { server } from './utils/server';
import { authHeader } from './helpers/authHeader';
import CacheBuster from './CacheBuster';
import VesrionUpdate from './components/modals/versionUpdate';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ReactGA from 'react-ga';
import ModalComponent from './components/modalComponent';
import Notification from './pages/marketPlace/property/notification';
import initializeAnalytics from '../src/analytics';
import NoInternet from './components/modals/noInternet';

initializeAnalytics();

const interCeptor = () => {

  server.interceptors.request.use((config) => {
    const token = authHeader();
    config.headers.Authorization = token;
    return config;
  }, null, { synchronous: true });
}
function App() {
  const [networkOffLine, setNetworkOffline] = useState(false);
  function checkInternetConnection() {
    if (!navigator.onLine) {
      setNetworkOffline(true);
    }
  }
  useEffect(() => {
    checkInternetConnection();
    const onlineHandler = () => {
      setNetworkOffline(false);
      console.log('You are online!');
    };
    const offlineHandler = () => {
      checkInternetConnection();
    };
    window.addEventListener('online', onlineHandler);
    window.addEventListener('offline', offlineHandler);
    return () => {
      window.removeEventListener('online', onlineHandler);
      window.removeEventListener('offline', offlineHandler);
    };
  }, []);

 
  interCeptor()
  useEffect(() => {
    
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
  }, []);

  return (
    <ThemeProvider>
      <GoogleOAuthProvider clientId="319102982878-s71f4e19hp1s0un042ibg5t098k4n87l.apps.googleusercontent.com">
        <CacheBuster>
          {({ loading, isLatestVersion, refreshCacheAndReload }) => {
            if (loading) return null;
            if (!loading && !isLatestVersion) {
              refreshCacheAndReload();
              return (
                <VesrionUpdate open={true} />
              )
            }
            return (
              <Provider store={store}>
                <SnackbarProvider
                  anchorOrigin={{
                    horizontal: "center",
                    vertical: "bottom"
                  }}
                  autoHideDuration={5000}
                  Components={{
                    success1: CustomSnackbar
                  }} >
                  <ScrollToTop />
                  <Router />
                </SnackbarProvider>
              </Provider>
            )
          }}
        </CacheBuster>
        {networkOffLine && (
          <NoInternet open={networkOffLine}/>
          // <ModalComponent
          //   open={networkOffLine}
          //   children={<Notification message="This property is temporarily unavailable." redirectPath="/dashboard" />}
          // />
        )}
      </GoogleOAuthProvider>
    </ThemeProvider>
  );
}

export default App;
