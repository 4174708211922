import moment from "moment";

export const formatDate = (timestamp) => {
   
    if (timestamp > 9999999999) {
        timestamp = Math.floor(timestamp / 1000);
    }
    return moment.unix(timestamp);
};

