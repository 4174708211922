
import {
    Button,
    Card,
    CardContent,
    Divider,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
    Container,
    Box,
    Skeleton,
    Grid,
    Backdrop,
    CircularProgress
  } from "@mui/material";
  import React, { useState, useEffect } from "react";
  import { useSelector, useDispatch } from "react-redux";
  import { useSnackbar } from "notistack";
  
  
  import { FormDatePicker } from "../form/datePicker";
import authServices from "../../services/authServices";
import ModalComponent from "./modalComponent";
import FormField from "../form/formField";
import FormSelect from "../form/formSelect";
import { saveUser } from "../../redux/reducers/userSlice";
import moment from "moment";

export default function EditProfileModal({open,handleClose,dispatch}) {

  const user = useSelector((state) => state.user.data);
  const [isEditing, setIsEditing] = useState(false);
  const [isBankEditing, setIsBankEditing] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  
  const [usernameError, setUsernameError] = useState(false);
  const [openProgressBar, setOpenProgressBar] = useState(false);



  const [formData, setFormData] = useState({
    username: "",
    mobile: "",
    gender: "",
    citizenShip: "",
    dob: null,
    emailId:"",
    userAddress: {
      flatNo: "",
      area: "",
      landmark: "",
      town: "",
      state: "",
      pincode: "",
    },
    aadharAddress: {
      flatNo: "",
      area: "",
      landmark: "",
      town: "",
      state: "",
      pincode: "",
    },
  });
  const [bankDetails, setBankDetails] = useState({
    accountNumber: "",
    ifscCode: "",
  });

  useEffect(() => {
    if (user) {
      setFormData({
        username: user.username || "",
        mobile: user.mobileNumber || "",
        gender: user.gender || "",
        citizenShip: user.citizenShip || "",
        dob: user.dob ? moment.unix(user.dob) : null,
        emailId:user.emailId || "",
        userAddress: {
          flatNo: user?.userAddress?.flatNo,
          area: user?.userAddress?.area,
          landmark: user?.userAddress?.landmark,
          town: user?.userAddress?.town,
          state: user?.userAddress?.state,
          pincode: user?.userAddress?.pincode,
        },
        aadharAddress: {
          flatNo: user?.aadharAddress?.flatNo,
          area: user?.aadharAddress?.area,
          landmark: user?.aadharAddress?.landmark,
          town: user?.aadharAddress?.town,
          state: user?.aadharAddress?.state,
          pincode: user?.aadharAddress?.pincode,
        },
      });
      setBankDetails({
        accountNumber: user.bankInformation?.accNumber || "",
        ifscCode: user.bankInformation?.ifsc || "",
      });
    }
  }, [user]);

//   const genderOptions = [
//     { title: "Male", value: "Male" },
//     { title: "Female", value: "Female" },
//     { title: "Others", value: "Others" },
//   ];


//   const handleEditClick = () => {
//     setIsEditing(true);
//   };

  const handleBankEditClick = () => {
    setIsBankEditing(true);
  };

  const handleSaveClick = async () => {
    setIsEditing(false);
    let userDetails = { ...formData, dob: new Date(formData.dob).valueOf() / 1000, _id: user._id }
    const res = await authServices.updatePersonalDetails(userDetails);
    if(res && res.success){
      enqueueSnackbar("Profile updated", { variant: "success", anchorOrigin: { horizontal: "right", vertical: "top" }, autoHideDuration: 3000 });
       dispatch(saveUser(res.data));
      handleClose(true);
    }
    else{
      enqueueSnackbar("Somthing Went Wrong", { variant: "error", anchorOrigin: { horizontal: "right", vertical: "top" }, autoHideDuration: 3000 });

    }
  };

  const handleBankSaveClick = async () => {
    setIsBankEditing(false);
    let updatedBankDetails = { ...bankDetails, _id: user._id }
    setOpenProgressBar(true);
    const res = await authServices.updateBankDetails(updatedBankDetails);
    setOpenProgressBar(false);
   
      if(res && res.success){
        console.log(res.data);    
        enqueueSnackbar("Bank Details updated", { variant: "success", anchorOrigin: { horizontal: "right", vertical: "top" }, autoHideDuration: 3000 });
      }
      else{
        enqueueSnackbar(res.data?.message || "Somthing Went Wrong", { variant: "error", anchorOrigin: { horizontal: "right", vertical: "top" }, autoHideDuration: 3000 });
  
      }
 
  };


  

  const handleBankCancelClick = () => {
    if (user) {
      setBankDetails({
        accountNumber: user.bankInformation?.accNumber || "",
        ifscCode: user.bankInformation?.ifsc || "",
      });
    }
    setIsBankEditing(false);
  };

  const handleBankChange = (field, value) => {
    setBankDetails((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };


  const handleCancelClick = () => {
    if (user) {
      setFormData({
        username: user.username || "",
        mobile: user.mobileNumber || "",
        gender: user.gender || "",
        citizenShip: user.citizenShip || "",
        dob: user.dob ? moment.unix(user.dob) : null,
        emailId:user.emailId || "",
        userAddress: {
          flatNo: user?.userAddress?.flatNo,
          area: user?.userAddress?.area,
          landmark: user?.userAddress?.landmark,
          town: user?.userAddress?.town,
          state: user?.userAddress?.state,
          pincode: user?.userAddress?.pincode,
        },
        aadharAddress: {
          flatNo: user?.aadharAddress?.flatNo,
          area: user?.aadharAddress?.area,
          landmark: user?.aadharAddress?.landmark,
          town: user?.aadharAddress?.town,
          state: user?.aadharAddress?.state,
          pincode: user?.aadharAddress?.pincode,
        },
      });
      setBankDetails({
        accountNumber: user.bankInformation?.accNumber || "",
        ifscCode: user.bankInformation?.ifsc || "",
      });
    }
    handleClose();
  };

  const handleChange = (field, value) => {
    setFormData((prevState) => {
      const keys = field.split('.');

      if (keys.length === 1) {
        return {
          ...prevState,
          [field]: value,
        };
      }

      const [mainKey, subKey] = keys;
      return {
        ...prevState,
        [mainKey]: {
          ...prevState[mainKey],
          [subKey]: value,
        },
      };
    });
  };

 

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

 

  return (
    <ModalComponent open={open} handleClose={handleClose}>
      <Container>
      <Backdrop open={openProgressBar} sx={{ color: "#ddd", zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Box
                    sx={{
                        position: "absolute",
                        inset: 0,
                        zIndex: (theme) => theme.zIndex.modal + 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <CircularProgress
                        size={75}
                        thickness={4}

                    />
                </Box>
            </Backdrop>

        <Card sx={{ mt: 2 }}>
          <CardContent sx={{ mx: { lg: 5, md: 4, sm: 2 } }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mt={1}
            >
              <Typography variant="mainCardTitle">
                Edit Profile
              </Typography>

              <Stack direction={'row'} columnGap={1}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSaveClick}
                  disabled={usernameError}
                  size="small"
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  onClick={handleCancelClick}
                  size="small"
                >
                  Cancel
                </Button>

              </Stack>
            </Stack>
            <Divider
              sx={{
                width: '100%',
                mt: 1,
                mb: 2,
              }}
            />
            {formData ? (<Stack>
              <Box sx={{ padding: "20px", borderRadius: "10px", boxShadow: 2 }}>
                <Typography variant="headerMenu">Personal Info</Typography>
                <Typography variant="subtitle1">
                  Update your personal details here
                </Typography>
                < Stack direction={isSmallScreen ? "column" : "row"} spacing={{ lg: 5, md: 5, sm: 2 }} mt={3}>
                  <Stack>
                    <FormField
                      label={"User Name"}
                      inputProps={{
                        // disabled: !isEditing,
                        value: formData.username,
                        onChange: (e) => {
                          if (e.target.value?.trim() == "") setUsernameError(true);
                          else setUsernameError(false);
                          handleChange("username", e.target.value);
                        },
                      }}
                    />
                    {usernameError && <Typography sx={{ fontSize: "0.9rem !important", marginTop: "5px", color: "red" }}>Invalid Username!</Typography>}
                  </Stack>
                  <FormField
                    label={"Legal Name"}
                    inputProps={{
                      disabled: true,
                      value: user?.legalName
                    }}
                  />
                  <FormField
                    label={"Mobile no"}
                    inputProps={{
                      disabled: true,
                      value: formData.mobile
                    }}
                  />

                </Stack>


                <Stack direction={isSmallScreen ? "column" : "row"} spacing={{ lg: 5, md: 5, sm: 2 }} mt={3}>
                  <FormField
                    label="Email"
                    inputProps={{
                      disabled: true,
                      value: formData.emailId,
                    }}
                  />
                  <FormDatePicker
                    label="Date Of Birth"
                      value={formData.dob}
                      minAge={18}
                      onChange={(date) => handleChange("dob", date)}                  
                  />
                  <FormField
                    label="Citizenship"
                    inputProps={{
                      disabled: true,
                      value: formData.citizenShip,
                    }}
                  />
                </Stack>
                <Stack direction={isSmallScreen ? "column" : "row"} spacing={{ lg: 5, md: 5, sm: 2 }} mt={3}>
                  {/* <FormSelect
                options={genderOptions}
                label="Gender"
                props={{
                  disabled: !isEditing,
                  value: formData.gender,
                  onChange: (e) => handleChange("gender", e.target.value),
                }}
              /> */}
                </Stack>
              </Box>
              <Box sx={{ padding: "20px", borderRadius: "10px", boxShadow: "2", marginTop: "30px" }}>
                <Typography variant="headerMenu">Address Info</Typography>

                <Typography variant="h5" sx={{ marginTop: 3, fontWeight: "400" }}>
                  Correspondence Address
                </Typography>
                <Stack direction={isSmallScreen ? "column" : "row"} spacing={{ lg: 5, md: 5, sm: 2 }} mt={1}>
                  <FormField
                    label={"Flat No,House No"}
                    inputProps={{

                      value: formData.userAddress.flatNo,
                      onChange: (e) => handleChange("userAddress.flatNo", e.target.value),
                    }}
                  />
                  <FormField
                    label={"Area,Street"}
                    inputProps={{
                      value: formData.userAddress.area,
                      onChange: (e) => handleChange("userAddress.area", e.target.value),
                    }}
                  />
                  <FormField
                    label={"Landmark"}
                    inputProps={{
                      value: formData.userAddress.landmark,
                      onChange: (e) => handleChange("userAddress.landmark", e.target.value),
                    }}
                  />
                </Stack>
                <Stack direction={isSmallScreen ? "column" : "row"} spacing={{ lg: 5, md: 5, sm: 2 }} mt={3}>
                  <FormField
                    label={"Town/City"}
                    inputProps={{
                      value: formData.userAddress.town,
                      onChange: (e) => handleChange("userAddress.town", e.target.value),
                    }}
                  />
                  <FormField
                    label={"State"}
                    inputProps={{
                      value: formData.userAddress.state,
                      onChange: (e) => handleChange("userAddress.state", e.target.value),
                    }}
                  />
                  <FormField
                    label={"Pincode"}
                    inputProps={{
                      value: formData.userAddress.pincode,
                      onChange: (e) => handleChange("userAddress.pincode", e.target.value),
                    }}
                  />
                </Stack>
                <Divider
                  sx={{
                    width: '100%',
                    mt: 3,
                    mb: 5,
                  }}
                />
                <Typography variant="landingSubText" sx={{ marginTop: 5 }}>
                  Aadhar Address
                </Typography>

                <Stack direction={isSmallScreen ? "column" : "row"} spacing={{ lg: 5, md: 5, sm: 2 }} mt={1}>
                  <FormField
                    label={"Flat No,House No"}
                    inputProps={{
                      disabled: Boolean(user?.aadharAddress?.flatNo),
                      value: formData?.aadharAddress?.flatNo,
                      onChange: (e) => handleChange("aadharAddress.flatNo", e.target.value),
                    }}
                  />

                  <FormField
                    label={"Area,Street"}
                    inputProps={{
                      disabled: Boolean(user?.aadharAddress?.area),
                      value: formData?.aadharAddress?.area,
                      onChange: (e) => handleChange("aadharAddress.area", e.target.value),
                    }}
                  />
                  <FormField
                    label={"Landmark"}
                    inputProps={{
                      disabled: Boolean(user?.aadharAddress?.landmark),
                      value: formData?.aadharAddress?.landmark,
                      onChange: (e) => handleChange("aadharAddress.landmark", e.target.value),
                    }}
                  />
                </Stack>
                <Stack direction={isSmallScreen ? "column" : "row"} spacing={{ lg: 5, md: 5, sm: 2 }} mt={3}>
                  <FormField
                    label={"Town/City"}
                    inputProps={{
                      disabled: Boolean(user?.aadharAddress?.town),
                      value: formData?.aadharAddress?.town,
                      onChange: (e) => handleChange("aadharAddress.town", e.target.value),
                    }}
                  />
                  <FormField
                    label={"State"}
                    inputProps={{
                      disabled: Boolean(user?.aadharAddress?.state),
                      value: formData?.aadharAddress?.state,
                      onChange: (e) => handleChange("aadharAddress.state", e.target.value),
                    }}
                  />
                  <FormField
                    label={"Pincode"}
                    inputProps={{
                      disabled: Boolean(user?.aadharAddress?.pincode),
                      value: formData?.aadharAddress?.pincode,
                      onChange: (e) => handleChange("aadharAddress.pincode", e.target.value),
                    }}
                  />
                </Stack>
              </Box>
              <Box sx={{ padding: "20px", borderRadius: "10px", boxShadow: "2", marginTop: "30px" }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="headerMenu">Bank Details</Typography>
                  {isBankEditing ? (
                    <Stack direction="row" spacing={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleBankSaveClick}
                        size="small"
                        sx={{ padding: '10px 12px !important' }}
                      >
                        Save
                      </Button>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleBankCancelClick}
                        size="small"
                        sx={{ padding: '10px 12px !important' }}
                      >
                        Cancel
                      </Button>
                    </Stack>
                  ) : (
                    <>
                    { !(bankDetails?.accountNumber && bankDetails?.ifscCode) &&
                      <Button
                      variant="contained"
                      color="primary"
                      onClick={handleBankEditClick}
                      size="small"
                      sx={{ padding: '10px 12px !important' }}
                      >
                      Edit
                    </Button>}
                    </>
                  )}
                </Stack>
                <Stack direction={{ md: "row", xs: "column" }} spacing={{ lg: 5, md: 5, xs: 2 }} mt={3}>
                  <FormField
                    label={"Account Number"}
                    inputProps={{
                      disabled: !isBankEditing,
                      value: bankDetails?.accountNumber,
                        onChange: (e) => handleBankChange("accountNumber", e.target.value),
                    }}
                  />
                  <FormField
                    label={"IFSC Code"}
                    inputProps={{
                      disabled: !isBankEditing,
                      value: bankDetails?.ifscCode,
                        onChange: (e) => handleBankChange("ifscCode", e.target.value),
                    }}
                  />
                </Stack>


              </Box>

            </Stack>) : (<><Box marginTop={"-110px"}>
              <Skeleton width={"100%"} height={400} />
            </Box>
              <Box marginTop={"-110px"}>
                <Skeleton width={"100%"} height={400} />
              </Box>
              <Box marginTop={"-110px"}>
                <Skeleton width={"100%"} height={400} />
              </Box>
            </>)}
          </CardContent>
        </Card>
      </Container>

    </ModalComponent>
  )
}
