import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import React from "react";
import CustomIcon from "../customIcon";

export default function FormCheckBox({value=false,onChange=()=>{},label,labelStyle={}}){
    return(
        <FormControlLabel 
        labelPlacement="end"
        control={
        <Checkbox 
        checked={value}
        onChange={onChange}
            icon={
                <Box
                    sx={{
                        width:22,
                        height:22,
                        border:"1px solid",
                        borderColor:"divider",
                        borderRadius:0.5
                    }}
                >
                </Box>
            }

            checkedIcon={
                <Box
                    sx={{
                        width:22,
                        height:22,
                        border:"1px solid",
                        borderColor:"primary.main",
                        bgcolor:"primary.main",
                        borderRadius:0.5,
                        display:"flex",
                        justifyContent:"center",
                        alignItems:"center",
                        
                    }}
                >
                    <CustomIcon name="check" sx={{bgcolor:"white",width:14}} />
                </Box>
            }
            />
        } 
        label={
            <Typography sx={labelStyle} variant="body2" ml={"13px"}>
                 {label}
            </Typography>
        } />
    )
}