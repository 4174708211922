import { Box, Button, Card, Chip, Divider, LinearProgress, Stack, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import transactionServices from "../services/transactionServices";
import { calculatePercent, convertToIndianFormat, getTitle } from "../utils/functions";
import CustomIcon from "./customIcon";
import CustomTable from "./customTable";
import { useSnackbar } from "notistack";
import { DetailButton, TableRow, TableRowImg, TableRowMultilineInfo, TxnRefNumber, AmountPaidCommited, TableRowMultilinePropertyinfo } from "./miniComponents";
import PaymentMode from "./modals/paymentMode";
import ConfirmaTransaction from "./modals/confirmTransaction";
import ModalComponent from "./modals/modalComponent";
import AllTransactionByListing from "./transactionTable/AllTransactionByListingNdUser";
import DataGridTable from "./DataGridTabel";
import userServices from "../services/userServices";
import Tabs from "./tabs/tabs";
import MobileBottomTab from "./tabs/mobileBottomTab";
import { margin } from "@mui/system";

export const PropertyTable = ({ sendDataToParent, isHuf, isFamilyMember }) => {
    const user = useSelector(state => state.user.data);
    const [data, setData] = useState([]);
    const [openTransactionModal, setOpenTransationModal] = useState(false);
    const [transactionProp, setTransactionProp] = useState({});


    useEffect(() => {
        getMyInvestments();
    }, [isHuf])

    const getMyInvestments = async () => {
        const data = {
            id: user._id,
            isHuf: isHuf,
            isFamilyMember: isFamilyMember
        }
        const res = await transactionServices.getPortfolioDetails(data);
        sendDataToParent([]);

        if (res && res.success && res.data?.length > 0) {
            let tempData = [];
            for (let e of res.data) {
                sendDataToParent(res.data);
                if (!e?.listingId) continue;
                let temp = {
                    propertyInfo:
                        [
                            getTitle(e) + "-" + e.projectName || e.projectName,
                            res.url + e?.listingImages || "",
                            e.projectName,
                            e.propertyCode
                        ],
                    imgUrl: res.url + e?.listingImages || "",
                    propertyName: getTitle(e) + "-" + e.projectName,
                    rightToOwnerShip: e.rightToOwnerShip ? "RCO" : "Tokenization",
                    propertyId: e.listingId,
                    projectName: e.projectName,
                    frax: e.totalNoOfFracs,
                    investAmt: e.totalAmtPaid || 0,
                    pendingAmount: (e.pendingAmt) || 0,
                    date: moment.unix(e.created_at).format("DD/MM/YYYY") || 12 / 12 / 2000,
                    uDate: moment.unix(e.created_at).format("DD/MM/YYYY") || 12 / 12 / 2000,
                    percentInvested: `${e.totalPropertyPercent.toFixed(2)} %`,
                    status: e.status,
                    listingId: e.listingId,
                    projectId: e.projectId,
                    _id: e._id,
                    transactionId: e.transactionId,
                    saleDeed: res.url + (e?.listing?.registrationCertificate[0]?.docUrl || ""),
                    ...(isFamilyMember && { username: e.username, relation: e.relation })
                };
                tempData.push(temp);
            }
            setData(tempData);

        } else {
            setData([]);
        }
    };



    const tabel = [
        {
            field: 'imgUrl',
            headerName: 'Property Image',
            flex: 1,
            renderCell: (params) => {
                ''
                return <><div style={{ display: "flex", justifyContent: "center", width: "100%", height: "100%", padding: "2px" }}><img src={params.row.imgUrl} alt="Property" style={{ width: '100px', height: 'auto', borderRadius: "4px" }} /></div>
                </>;
            }
        },
        { field: 'propertyName', headerName: 'Property Name', flex: 1, },
        {
            field: 'investAmt', headerName: 'Total Amount Invested', flex: 1,
            valueFormatter: (params) => {
                return `₹${params.toLocaleString('en-IN')}`
            }
        },
        { field: 'frax', headerName: 'Total No of FRAX', flex: 1 },
        { field: 'rightToOwnerShip', headerName: 'Investment Mode', flex: 1 },
        isFamilyMember && {
            field: 'username', headerName: 'Investor Name', flex: 1,
            renderCell: (params) => {
                return <Typography variant="body3">{params.row.username ? `${params.row.username}(${params.row.relation})` : "Family"}</Typography>
            }
        },
        { field: 'percentInvested', headerName: 'Total % Ownership', flex: 1 },
        {
            field: 'prop',
            headerName: '',
            flex: 2,
            renderCell: (params) => {
                const handleClick = () => {
                    if (params.row.saleDeed) {
                        window.open("https://go.realx.in/sale-deed/" + params.row?.listingId, '_blank');
                    } else {
                        alert("sale deed not uploaded yet");
                    }
                };

                return (
                    <Button
                        onClick={handleClick}
                        style={{ padding: '12px 15px' }}
                        variant="outlined"
                    >
                        {'View Sale Deed'}
                    </Button>
                );
            },
        },

    ].filter(Boolean);



    if (data.length === 0)
        return (<EmptyTableState showAction={true} title={"No Properties"} subtitle={"You've not made any investments yet."} />)

    return (
        <>

            <DataGridTable initialColumns={tabel} initialRows={data} />


            {/* {openTransactionModal && (
                <ModalComponent
                    open={openTransactionModal}
                    handleClose={() => setOpenTransationModal((prev) => !prev)}
                    title="All Transaction"
                    titleStyle={{ fontWeight: 400, mb: 1, pl: 1, fontSize: "1.4rem !important" }}
                    children={<AllTransactionByListing data={transactionProp} />}
                />
            )} */}
        </>
    );
}

export const WatchListTable = ({ watchlist }) => {

    // const generatePropertyName = (propertyData) => {
    //     if (propertyData?.propertyType === "Residential") return `${propertyData?.basicInfo?.flatNo}, Wing-${propertyData?.basicInfo?.wing}`
    //     if (propertyData?.propertyType === "Commercial" || propertyData?.propertyType === "Retail") return `${propertyData?.basicInfo?.unitNo}, Wing-${propertyData?.basicInfo?.wing}`
    //     if (propertyData?.propertyType === "Land") return `Plot No. ${propertyData?.basicInfo?.plotNo} - ${propertyData?.project?.projectName}`
    //     if (propertyData?.propertyType === "Warehouse") return `Warehouse No. ${propertyData?.basicInfo?.warehouseNo}`
    //     return "";
    // }

    const [data, setData] = useState([]);

    const navigate = useNavigate();


    useEffect(() => {
        const temp = [];
        if (watchlist && watchlist.length > 0) {
            watchlist.map((e) => {
                temp.push({
                    _id: e._id,
                    propertyName: getTitle(e),
                    propertyType: e.propertyType,
                    totalPurchasePrice: convertToIndianFormat(e.transactionDetails.totalCostOfPurchase),
                    totalInvestors: e.transactions?.investors?.length || 0,
                    filled: `${calculatePercent((e.numberOfFracs - e.remainingFracs), e.numberOfFracs).toFixed(2)} %`,
                    status: e.status,
                    totalAmount: calculateTotalRetailCost(e),
                });
                return null;
            });
        }
        setData(temp)
    }, [watchlist])

    const table = [
        { field: 'propertyName', headerName: 'Property Name', flex: 1 },
        { field: 'propertyType', headerName: 'Property Type', flex: 1 },
        {
            field: 'totalAmount', headerName: 'Total Purchase Price', flex: 1,
            valueFormatter: (params) => {
                return `₹${params.toLocaleString('en-IN')}`
            }
        },
        { field: 'totalInvestors', headerName: 'Total no of investors', flex: 1 },
        { field: 'filled', headerName: '% Filled', flex: 1 },
        {
            field: 'prop',
            headerName: '',
            flex: 1,
            renderCell: (params) => {


                const handleClick = () => {

                    navigate(`/dashboard/property/${params.row._id}`, {
                        state: { id: params.row._id },
                    });
                };

                return (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        {params.row.status === "COMPLETED" ? <Chip
                            label="Fully Subscribed"
                            variant="outlined"
                            sx={{
                                color: "#ff7676",
                                fontWeight: "bold",
                                fontSize: "0.8rem",
                                padding: "5px 10px",
                                border: "none"
                            }}
                        />
                            :
                            <Button
                                onClick={handleClick}
                                style={{ padding: '12px 15px' }}
                                variant="outlined"
                            >
                                Show Property
                            </Button>}
                    </div>
                );
            },
        }
    ]

    const calculateTotalRetailCost = (propertyData) => {
        const currentTotalCost = propertyData?.transactionDetails?.totalCostOfPurchase;
        const currentSalePrice = propertyData?.transactionDetails?.salePrice;
        const retailSalePrice = propertyData?.transactionDetails?.retailBasePricePSF * propertyData?.characteristics?.builtupArea;
        if (propertyData?.status == "LIVE") return currentTotalCost - currentSalePrice + retailSalePrice - propertyData?.transactionDetails?.gstINR + (retailSalePrice * propertyData?.transactionDetails?.gst / 100) - propertyData?.transactionDetails?.stampDutyINR + (retailSalePrice * propertyData?.transactionDetails?.stampDuty / 100);
        else return currentTotalCost - currentSalePrice + retailSalePrice;
    }
    const FraxRow = (props) => {

        return (
            <Stack direction={"row"} spacing={3} alignItems={"center"}>
                <LinearProgress value={props.children} variant="determinate" color="success" sx={{ height: 9, width: 120 }} />
                <Typography variant="body2">{props.children} %</Typography>
            </Stack>
        )
    }

    // const tableCells = [<TableRow />, <TableRow />, <TableRow />, <TableRow />, <FraxRow />, <DetailButton />];

    if (data.length > 0) {
        return (
            <DataGridTable initialColumns={table} initialRows={data} />

        )
    }
    else {
        return (<EmptyTableState showAction={true} title={"No Properties"} subtitle={""} />)
    }
}



export const EmptyTableState = ({ showAction = false, title, subtitle }) => {
    const navigate = useNavigate();
    return (
        <Box
            sx={{
                flex: 1,
                width: "100%",

            }}
        >
            <Stack height={"100%"} direction={"column"} alignItems={"center"} justifyContent={"center"}>
                <Box
                    sx={{
                        width: { md: 160, xs: 88 }, height: { md: 160, xs: 88 },
                        bgcolor: "primary.light",
                        borderRadius: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mt: { md: 0, xs: 3 }
                    }}
                >
                    <CustomIcon name="file-document" sx={{ bgcolor: "primary.main", width: { md: 72, xs: 32 }, height: { md: 72, xs: 32 }, }} />
                </Box>
                <Typography sx={{ fontSize: "28px", fontWeight: 500, mt: { md: 4, xs: 3 }, mb: { md: 1.5, xs: 0.5 } }}>{title}</Typography>
                <Typography variant="subtitle1" sx={{ fontSize: "16px", fontWeight: 300, mb: { md: 3, xs: 2 } }}>{subtitle}</Typography>
                {showAction && <Button onClick={() => navigate("/dashboard")} sx={{ mb: { md: 0, xs: 3 } }} variant="contained">Explore Properties to invest</Button>}
            </Stack>
        </Box>
    )
}

export const TransactionsTable = ({ transactionsDetails, getTransactionDetails,title}) => {
    const [data, setData] = useState([]);
    const [paymentModeModal, setPaymentModeModal] = useState(false);
    const [paymentModal, setPaymentModal] = useState(false);
    const [commitmentDate, setCommitmentDate] = useState()
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const user = useSelector(state => state.user.data);
    const [currentTraxn, setCurrentTraxn] = useState();
    const [openTransactionModal, setOpenTransationModal] = useState(false);
    const [transactionProp, setTransactionProp] = useState({});
    const [status, setStatus] = useState();
    const [noOfFrax, setNoOfFrax] = useState();
    const [propertyId, setPropertyId] = useState();
    const [activeTab,setActiveTab] = useState(0);
    const [paymentAccount,setPaymentAccount]=useState(false)
    const handleTabClick = (index) =>{
        setActiveTab(index);
    }
    const getLastPaymentDate = (payments) => {
        if (!payments || payments.length === 0) return "-";
        for (let i = payments.length - 1; i >= 0; i--) {
            if (payments[i].status !== "REFUNDED" && payments[i].dateoftrans) {
                return moment.unix(payments[i].dateoftrans).format("DD/MM/YYYY");
            }
        }
        return "-";
    }

     
    useEffect(() => {

        const temp = [];
        if (transactionsDetails && transactionsDetails.length > 0) {

            transactionsDetails.map((e) => {
                temp.push({
                    _id: e._id,
                    listingId: e.listingId,
                    propertyInfo: [
                        getTitle(e.listing) || "",
                        transactionsDetails[0].url + e?.listing?.listingImages[0]?.docUrl || "",
                        e.project.projectName,
                        e.listing.propertyCode
                    ],
                    imgUrl: transactionsDetails[0].url + e?.listing?.listingImages[0]?.docUrl || "",
                    lastPaymentDate: getLastPaymentDate(e?.payments),
                    payments: e?.payments?.map(payment => ({
                        ...payment,
                        rpf: e?.rpf,
                        amount: e?.amount,
                    })) || [],
                    rpf: (e?.rpf || e?.amount / e?.noOfFracs)?.toFixed(2),
                    projectName: e.project.projectName,
                    propertyName: `${getTitle(e.listing)}-${e.project.projectName}`,
                    propertyType: e.listing?.propertyType,
                    amount: e?.amount,
                    amtTobePaid: (e?.amtTobePaid || 0),
                    noOfFracs: e?.noOfFracs || 0,
                    propertyPercent: e.propertyPercent,
                    propertyStatus: e.listing?.status || "",
                    pendingAmt: e.pendingAmt || 0,
                    highValueCashback: e.highValueCashback,
                    created_at: moment(e.created_at).format("DD/MM/YYYY"),
                    updated_at: moment(e.updated_at).format("DD/MM/YYYY"),
                    commitmentDate: e.created_at,
                    couponCashback: e.couponCashback,
                    status: e.status,
                    transactionId: e.transactionId,
                    newpaymentMade: e.newpaymentMade,
                    fraxFree: e.fraxFree,
                    amtPaid: e.amtPaid,
                    user_id: e.user_id,
                    isHuf: e.isHuf,
                    familyMemberId: e.familyMemberId,
                    username: e.familyMember?.username,
                    relation: e.familyMemberInvestor?.relation
                });

                return null;
            });
        }
        setData(temp);
    }, [transactionsDetails]);
    
    const huftransaction = data.filter(transaction => transaction.isHuf === true);
   
    const familymemberTransaction = data.filter(data => 
        'familyMemberId' in data && data.familyMemberId !== null&&data.familyMemberId!==undefined
    );
    const personalTransaction=data.filter(transaction => transaction.isHuf === false&&transaction.familyMemberId == null&&transaction.familyMemberId==undefined);
   
    const table = [
        {
            field: 'imgUrl',
            headerName: 'Property Image',
            flex: 1,
            renderCell: (params) => {
                return <><div style={{ display: "flex", justifyContent: "center", width: "100%", height: "100%", padding: "2px" }}><img src={params.row.imgUrl} alt="Property" style={{ width: '100px', height: 'auto', borderRadius: "4px" }} /></div>
                </>;
            }
        },
        { field: 'propertyName', headerName: 'Property Name', flex: 1 },
        {
            field: 'amount', headerName: 'Amount Paid/ Commited', flex: 1.5,
            valueFormatter: (params) => {
                return `₹${params.toLocaleString('en-IN')}`
            },
            renderCell: (params) => {
                // console.log(params.row); // Logs the row data (useful for debugging)
                return <AmountPaidCommited data={params.row} />;  // Calls the AmountPaidCommited component
            }
        },
        { field: 'noOfFracs', headerName: 'No of FRAX', flex: 1 },
        // { field: 'rpf', headerName: 'Rate /FRAX', flex: 1 },
        paymentAccount?{
            field: "isHuf", headerName: "Payment Account", flex: 1.5, alignItems: "center", textAlign: "center",
            renderCell: (params) => {
                
                if (params.row.isHuf) {
                    return <Typography variant="body3">HUF</Typography>
                } else if (params.row.familyMemberId) {
                    return <Typography variant="body3">{params.row?.username ? `${params.row?.username}(${params.row?.relation})` : "Family"}</Typography>
                }
                else {
                    return <Typography variant="body3">Personal</Typography>
                }
            }
        }:null,
        { field: 'created_at', headerName: 'Committed Date', flex: 1 },
        { field: 'lastPaymentDate', headerName: 'Last Txn Date', flex: 1 },
        {
            field: 'transactionId', headerName: 'Transaction Ref No', flex: 1,
            renderCell: (params) => {
                return <div style={{ display: "flex", justifyContent: "center", height: "100%", padding: "2px" }}> <TxnRefNumber elemProps={params.row.transactionId} /> </div>
            }
        },
        {
            field: 'click', headerName: 'Complete Payment', flex: 2.5,
            renderCell: (params) => {


                return (<>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                        {params.row.status == "CREATED" ?
                            <Box sx={{
                                padding: "4px 12px",
                                bgcolor: `#fbf4e0`,
                                color: `#975e0a`,
                                borderRadius: "18px",
                                width: "fit-content",
                                display: "flex", justifyContent: "center"
                            }}>
                                <Typography style={{ fontSize: "12px" }} variant="body2">Payment Pending</Typography>
                            </Box>

                            :

                            params.row.status == "PARTPAID" && params.row?.newpaymentMade ?

                                <Box sx={{
                                    padding: "4px 12px",
                                    bgcolor: `#fbf4e0`,
                                    color: `#975e0a`,
                                    borderRadius: "18px",
                                    width: "fit-content",

                                    display: "flex", justifyContent: "center"
                                }}>
                                    <Typography style={{ fontSize: "12px" }} variant="body2">Verification Pending</Typography>
                                </Box>

                                :

                                (params.row.status == "PARTPAID" && params.row?.pendingAmt > 0 && !params.row?.newpaymentMade) ?
                                    <Button style={{ padding: "10px", fontSize: "12px" }} onClick={() => { setCurrentTraxn(params?.row); handleCompletePayment(params.row?.pendingAmt); setCommitmentDate(params.row?.commitmentDate) }} variant="outlined">Complete Remaining Payment</Button>

                                    :

                                    params.row.status == "APPROVED" || params.row.fraxFree ?

                                        <Box sx={{
                                            padding: "4px 12px",
                                            bgcolor: `success.light`,
                                            color: `success.main`,
                                            borderRadius: "18px",
                                            width: "fit-content",
                                            display: "flex", justifyContent: "center"
                                        }}>
                                            <Typography style={{ fontSize: "12px" }} variant="body2">
                                                Payment Completed
                                            </Typography>

                                        </Box>

                                        :

                                        params.row.status == "FRAXTRANSFERED" ?

                                            <Box sx={{
                                                padding: "4px 12px",
                                                bgcolor: `success.light`,
                                                color: `success.main`,
                                                borderRadius: "18px",
                                                width: "fit-content",
                                                display: "flex", justifyContent: "center"
                                            }}>
                                                <Typography style={{ fontSize: "12px" }} variant="body2">Payment Completed</Typography>
                                            </Box>

                                            :

                                            <Box sx={{
                                                padding: "4px 12px",
                                                bgcolor: `#ffdfdf`,
                                                color: `#df0a0a`,
                                                borderRadius: "18px",
                                                width: "fit-content",
                                                display: "flex", justifyContent: "center"
                                            }}>
                                                <Typography style={{ fontSize: "12px" }} variant="body2">Payment Rejected</Typography>
                                            </Box>

                        }</div></>)
            }
        },
        {
            field: 'proop',
            headerName: '',
            flex: 1.5,
            renderCell: (params) => {
                const handleClick = () => {
                    setTransactionProp(params.row.payments)
                    setStatus(params.row.status)
                    setNoOfFrax(params.row.noOfFracs)
                    setPropertyId(params.row.listingId)
                    setOpenTransationModal((prev) => !prev)
                };

                return (
                    <Button
                        onClick={handleClick}
                        style={{ padding: "10px", fontSize: "12px" }}
                        variant="contained"
                    >
                        {'View Payments'}
                    </Button>
                );
            },
        },

    ].filter(Boolean)

    const tabs = [
        {
            name: title || "All Transactions",
            tab: <AllTranSaction data={data} table={table} setPaymentAccount={setPaymentAccount}/>
        },
        {
            name: title || "Personal",
            tab: <PersonalTransaction data={personalTransaction} table={table} setPaymentAccount={setPaymentAccount} />
        },
        familymemberTransaction?.length > 0 ? {
            name: title || "Family Member",
            tab: <FamilyMemberTransaction data={familymemberTransaction} table={table} setPaymentAccount={setPaymentAccount}/>
        } : null,
        huftransaction?.length > 0 ? {
            name: title || "HUF",
            tab: <HUFTransaction data={huftransaction} table={table} setPaymentAccount={setPaymentAccount}/>
        } : null
    ].filter(Boolean);
    
    
    console.log()


    const handleCompletePayment = (amount) => {


        if (amount <= 80000) {
            setPaymentModeModal(true);
        }
        else {

            setPaymentModal(true);
        }
    }
    const handleBankTransfer = () => {
        setPaymentModeModal(false);
        setPaymentModal(true);
    }

    const handlePayementGateway = async () => {
        let reqData = {
            transactionId: currentTraxn._id,
            amount: currentTraxn.pendingAmt
        }
        // throw "abchdsh"
        const res = await transactionServices.createOrderRemAmt(reqData);
        setPaymentModeModal(false);
        // console.log(res);
        if (res && res.success) {
            const order = res.data;
            const options = {
                key: order.notes.key,
                amount: order.amount,
                currency: "INR",
                name: user.name,
                description: "Purchase FRAX",
                order_id: order.id,
                // callback_url: `https://pupperazy-backend.odinflux.com/transaction/verifyOrder/${order.notes.transactionId}`,
                // callback_url: `http://192.168.0.114:5000/transaction/verifyOrder/${order.notes.transactionId}`,
                // callback_url: `http://192.168.0.109:80/transaction/verifyOrder/${order.notes.transactionId}`,
                // callback_url: `${process.env.REACT_APP_API_URL}transaction/verifyOrder/${order.notes.transactionId}`,
                // callback_url: `https://api.pupperazy.com/transaction/verifyOrder/${order.notes.transactionId}`,
                handler: async (res) => {
                    // console.log(res,"test");
                    enqueueSnackbar("Payment successfull.", { variant: "success", anchorOrigin: { horizontal: "center", vertical: "bottom" }, autoHideDuration: 2000 })
                    // navigate("/portfolio")
                    await getTransactionDetails(currentTraxn.user_id)
                },
                prefill: {
                    name: user.name,
                    email: user.email,
                    contact: user.number
                },
                notes: {
                    "address": "REALX"
                },
                theme: {
                    "color": "#2F6BF2"
                },
                modal: {
                    escape: false,
                    confirm_close: true,
                    ondismiss: () => {
                        // console.log("fkdhf")
                    }
                }
            };
            const razor = new window.Razorpay(options);
            razor.on('payment.failed', function (response) {
            });
            razor.open();
        }
        else {
            enqueueSnackbar(res.data.message, { variant: "error", anchorOrigin: { horizontal: "center", vertical: "bottom" }, autoHideDuration: 2000 })
        }
    }
    const handleBankTransferSubmit = async () => {
        let reqData = {
            transactionId: currentTraxn._id,
            amount: currentTraxn.pendingAmt
        }
        if (reqData) {
            const req = await transactionServices.confirmSecondPartPayment(reqData);
            if (req && req.success) {
                enqueueSnackbar("Success", { variant: "success" });
                getTransactionDetails();
                setPaymentModal(false)
            }
            else {
                if (req.data) {
                    enqueueSnackbar(req.data.message, { variant: "error" });
                }
                else {
                    enqueueSnackbar("Something went wrong!!", { variant: "error" });
                }
            }
        }
    }

    if (data.length > 0) {
        return (
            <>
                {currentTraxn && <ConfirmaTransaction
                    commitmentDate={commitmentDate}
                    open={paymentModal}
                    handleClose={() => { setPaymentModal(false); getTransactionDetails(user._id) }}
                    getTransactionDetails={getTransactionDetails}
                    amtPaid={currentTraxn?.amtPaid || 0}
                    data={{ transactionId: currentTraxn?._id }}
                    amount={currentTraxn?.amount || 0} amtTobePaid={currentTraxn?.amtTobePaid}
                />}
                <PaymentMode open={paymentModeModal} handleClose={() => setPaymentModeModal(false)} onBankTransfer={handleBankTransfer} onPaymentGateway={handlePayementGateway} />

                {(huftransaction.length == 0 && familymemberTransaction.length == 0) && (
    <DataGridTable initialColumns={table} initialRows={data} />
)}

               {(huftransaction.length>0||familymemberTransaction.length>0)&&<Box pb={{xl:"0px",lg:"0px",md:"0px",xs:"77px"}}>
                                    <Box 
                                    sx={{mt:{md:2,xs:2}}}
                                    >
                                    <Stack>
                    
                                        <Box mb={3}  >
                                            <Box sx={{mx:"46px"}} >
                                        { data && 
                                            <Tabs activeTab={activeTab} tabs={tabs} onClick={handleTabClick} />
                                        }
                                        </Box>
                                        <Divider sx={{
                                                width:"calc(100% + 80px)",
                                                
                                            }} />
                                        </Box>
                                        
                                        
                                        <Box >
                                            {
                                                
                                                 tabs[activeTab]?.tab
                                            }
                                        </Box>
                                    </Stack>
                                    </Box>
                    
                                {/* } */}
                    
                                <MobileBottomTab />
                            </Box>}
                {openTransactionModal && (
                    <ModalComponent
                        open={openTransactionModal}
                        handleClose={() => setOpenTransationModal((prev) => !prev)}
                        title="All Payments"
                        titleStyle={{ fontWeight: 400, mb: 1, pl: 1, fontSize: "1.4rem !important" }}
                        children={<AllTransactionByListing data={transactionProp} status={status} noOfFrax={noOfFrax} propertyId={propertyId} />}
                    />
                )}
            </>
        )
    }
    else {
        return (<EmptyTableState showAction={false} title={"No Transactions Avaialble"} subtitle={""} />)
    }
}

export const AllTranSaction =({data,table,setPaymentAccount})=>{
    setPaymentAccount(true)
return (
    <DataGridTable initialColumns={table} initialRows={data} />
)
}

export const PersonalTransaction=({data,table,setPaymentAccount})=>{
setPaymentAccount(false);
    return (
        <DataGridTable initialColumns={table} initialRows={data} />
    )
}
export const FamilyMemberTransaction=({data,table,setPaymentAccount})=>{
setPaymentAccount(true)
    return (
        <DataGridTable initialColumns={table} initialRows={data} />
    )
}
export const HUFTransaction=({data,table,setPaymentAccount})=>{
    setPaymentAccount(false);
    return (
        <DataGridTable initialColumns={table} initialRows={data} />
    )
}

