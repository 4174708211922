import React, { useEffect, useState } from "react";
import transactionServices from "../../services/transactionServices";
import moment from "moment";
import { TxnRefId, TxnRefNumber } from "../miniComponents";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DataGridTable from "../DataGridTabel";
import { formatDate } from "../../hooks/useFormatDate";
import { render } from "@testing-library/react";
import Label from "../label";

const AllTransactionByListing = ({ data, status, noOfFrax,propertyId }) => {
  const [transactionData, setTransactionData] = useState();
  
  const navigate = useNavigate();
  const [totalFrax, setTotalFrax] = useState(0)
  
  useEffect(() => {

    const tempData = [];
    let tempTotalFrax = 0;
    data.map((e) => {
      // setTotalFrax(prevTotalFrax => prevTotalFrax + Math.round(((e.amountByAdmin || e.status!="REJECTED"&&e.amountTransferred) / e?.rpf).toFixed()) || 0);
      
      let currentPaymentFrax = 0;
      if(e.status != "REJECTED") currentPaymentFrax = ((e.amountByAdmin || e.amountTransferred) / e.rpf);
      tempTotalFrax += currentPaymentFrax;

      tempData.push({
        _id: e.paymentNo,
        rpf: `₹ ${(e?.rpf || e?.amount / noOfFrax)?.toFixed(2)}`,
        amountByAdmin: e.amountByAdmin || 0,
        amountTransferred: e.amountTransferred || 0,
        // frax: currentPaymentFrax,
        updated_at: e?.dateoftrans ? moment.unix(e?.dateoftrans).format("DD/MM/YYYY") : "-",
        actionTaken: e?.adminActionTakenOn ? formatDate(e?.adminActionTakenOn).format("DD/MM/YYYY") : "-",
        listingId: e.listingId,
        refId: e?.chequeno,
        transactionId: e?.transactionId,
        status: e?.status
      });
    });
    setTotalFrax(Math.floor(tempTotalFrax));
    setTransactionData(tempData)
  }, []);

  const table = [
    {
      field: '_id', headerName: 'Payment No', flex: 1,
    },
    {
      field: 'amountTransferred', headerName: 'Amount Transferred', flex: 1,
      valueFormatter: (params) => {
        return `₹${params?.toLocaleString('en-IN')}` || 0
      }
    },
    {
      field: 'amountByAdmin', headerName: 'Accepted Amount', flex: 1,
      valueFormatter: (params) => {
        return `₹${params?.toLocaleString('en-IN')}` || 0
      }
    },
    {
      field: 'refId', headerName: 'Reference ID', flex: 1,
      renderCell: (params) => {
        return <div style={{ display: "flex", justifyContent: "center", height: "100%" }}>
          {params.row.refId === null || params.row.refId === undefined ? "-" : <TxnRefId elemProps={params.row.refId} />}
        </div>
      }
    },
    // { field: 'frax', headerName: 'No of FRAX', flex: 1 },
    { field: 'rpf', headerName: 'Rate/FRAX', flex: 1 },
    { field: 'updated_at', headerName: 'Payment Date', flex: 1 },
    { field: 'actionTaken', headerName: 'Action Date', flex: 1 },
    {
      field: 'status', headerName: 'Status', flex: 1,
      renderCell: (params) => {
        return <div>
          {params?.row?.status ?
            <Label sx={{ py: 2, px: 2, fontWeight: 600 }} variant="ghost" color={params?.row?.status == 'PAID' ? 'warning' : params?.row?.status == 'REJECTED' ? 'error' : params?.row?.status == 'APPROVED' ? 'success' : "default"}>
              {params?.row?.status}
            </Label> : "-"}
        </div>
      }
    },
    // {
    //   field: 'transactionId', headerName: 'Transaction ID', flex: 1,
    //   renderCell: (params) => {
    //     return <div style={{ display: "flex", justifyContent: "center", height: "100%" }}><TxnRefNumber elemProps={params.row.transactionId} /></div>
    //   }
    // },
  ]

  return (
    <Box >
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: { lg: "1200px" } }}>
        <Box sx={{ display: "flex" }}><Typography sx={{ fontWeight: "500" }}>{(status !== "FRAXTRANSFERED" && status !== "APPROVED") ? 'Allocated FRAX' : 'Total FRAX'} : </Typography><Typography>{noOfFrax}</Typography></Box>
        {(status !== "FRAXTRANSFERED" && status !== "APPROVED") && (
          <Box sx={{ display: "flex" }}>
            <Typography sx={{ fontWeight: "500" }}> FRAX Owned : </Typography>
            <Typography>{totalFrax}</Typography>
          </Box>
        )}

        <Button variant="outlined" onClick={() => navigate(`/dashboard/property/${propertyId}`, { state: { id: propertyId } })} sx={{ float: 'right', padding: '10px', marginBottom: '10px' }}>
          Show property details
        </Button>
      </Box>
      <DataGridTable initialColumns={table} initialRows={transactionData} />

    </Box>
  )

};

export default AllTransactionByListing;