import { Stack, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from "moment";
import React from "react";


export function FormDatePicker({ 
  label, 
  labelVariant = "label", 
  minAge, 
  maxAge = 100, 
  value, 
  onChange, 
  ...props 
})
 
{
 
  return (
    <Stack direction={"column"} spacing={1}>
      <Typography variant={labelVariant}>{label}</Typography>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          value={value}
          views={["year", "month", "day"]}
          onChange={onChange}
          {...props}
          minDate={moment().subtract(maxAge, "years")}  
          maxDate={moment().subtract(minAge, "years")}  
          format="DD/MM/YYYY"
          inputFormat={"DD/MM/YYYY"}
          slotProps={{
            desktopPaper: {
              sx: {
                "& .MuiPickersYear-yearButton": {
                  color: "black", // Change the year selection color
                },
                "& .MuiPickersMonth-monthButton.Mui-disabled":{
                  color:"#c1b9b9",
                },
                "& .MuiPickersMonth-monthButton":{
                  color:"black",
                }
              },
            },
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    </Stack>
  );
}



export function CustomDatePicker({ label, labelVariant = "label", value, onChange, ...props }) {
  return (
    <Stack direction={"column"} spacing={1}>
      <Typography variant={labelVariant}>{label}</Typography>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          value={value}
          views={["year", "month", "day"]}
          onChange={onChange}
          {...props}
          format="DD/MM/YYYY"
          inputFormat="DD/MM/YYYY"
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    </Stack>
  );
}
