import { Box, Button, Stack, Typography, Grid, CircularProgress, Backdrop, FormControlLabel, Radio, FormControl, FormLabel, RadioGroup, Divider, ToggleButton, ToggleButtonGroup, keyframes } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import { LoadingButton } from "@mui/lab"
import React, { useEffect, useRef, useState } from "react";
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from "formik";
import Lottie from "lottie-react";
import { FormDatePicker } from "../form/datePicker";
import FormField from "../form/formField";
import FormFileField from "../form/formFileField";
import FormSelect from "../form/formSelect";
import ModalComponent from "./modalComponent";
import addressServices from "../../services/addressServices";
import authServices from "../../services/authServices";
import { useDispatch, useSelector } from 'react-redux';
import CustomIcon from "../customIcon";
import FormAutoCompleted from "../form/formAutoComplete";
import bankServices from './../../services/bankServices';
import { useSnackbar } from "notistack";
import { saveUser } from "../../redux/reducers/userSlice";
import check from "../../animations/check.json"
import moment from "moment";
import { PhoneInput } from 'react-international-phone';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import 'react-international-phone/style.css';
import AreYouSureModal from "./areYouSureModal";
import FormCheckBox from "../form/formCheckBox";
import PanCardMessage from "./panCardMessage";
import style from './accountSetup.module.css'
import { FamilyMemberRegister } from "../../pages/familyMember/registerPage";
import { AutoKYCForm } from "../../pages/familyMember/adhharCard";
import { ManualKYCForm } from "../../pages/familyMember/maunalKyc";
import BlockchainAcc from "../../pages/familyMember/BlockChainAccountSetup";
import familymemberServices from "../../services/familymemberServices";

export default function AddFamilyMember({ open, handleClose = () => { }, steps,familyMemberIdGet,getallFamilyMember }) {
    const [currentStep, setStep] = useState(0);
    const [blockchainAccountDownload, setBlockchainAccountDownload] = useState(false);
    const [blockchainAccountCreated, setBlockchainAccountCreated] = useState(false);
    const [animating, setAnimating] = useState(false);
    const [mnemonic, setmnemonic] = useState();
    const [publicAddress,setPublicAddress]=useState();
    const [otp, setOtp] = useState("");
    const [dltName, setDltName] = useState("");
    const [otpForm, setOtpForm] = useState(false);
    const [aadhaar, setAadhaar] = useState("");
    const [refId, setRefId] = useState("");
    const [openProgressBar, setOpenProgressBar] = useState(false);
    const modelRef = useRef();
    const [manualKyc, setManualKyc] = useState(false)
    const [encryptedMnemonic, setEncryptedMnemonic] = useState("");
    const [sendMail, setSendMail] = useState(true);
    const [isIndian, setIsIndian] = useState("YES")
    const [sendToFamilyMember, setSendToFamilyMember] = useState(false)
    const { enqueueSnackbar } = useSnackbar();
    const [familymemberId, setFamilymemberId] = useState(familyMemberIdGet)
    const [user,setUser] = useState({})
    const dispatch = useDispatch();
    const investor = useSelector(state => state.user.data)

    useEffect(() => {
        if (steps&&familyMemberIdGet) {
            setStep(steps);
            setFamilymemberId(familyMemberIdGet)
        }
    }, [steps,familyMemberIdGet])
   

    useEffect(() => {
        if (animating) {
            scrollToTop();
        }
    }, [animating])

    const customHandleClose = () => {
        if(currentStep !== 2) setStep(0)
        if(blockchainAccountCreated && blockchainAccountDownload){
            setStep(0)
            setBlockchainAccountCreated(false)
            setBlockchainAccountDownload(false)
        }
        setFamilymemberId("")
        if (currentStep !== 2 || !blockchainAccountCreated || blockchainAccountDownload) handleClose();
        else enqueueSnackbar("Please download key details before closing!", { variant: "error", anchorOrigin: { horizontal: "right", vertical: "top" }, autoHideDuration: 5000 });
        
        if (sendMail && blockchainAccountDownload) sendMnemonicThroughMail();
        if (sendToFamilyMember && blockchainAccountDownload) sendMailToFamilyMember();
    }

    const scrollToTop = () => {
        if (modelRef.current) {
            let modalBody = modelRef.current.querySelector('.MuiDialog-container')
            // console.log(modalBody)
            if (modalBody) {
                modalBody.scroll({
                    top: 0,
                    behavior: 'smooth'
                });
            }
        }
    }

    const sendMnemonicThroughMail = async () => {
        await authServices.sendMnemonicThroughMail({ data: user.walletInfo.publicAddress + "|" + encryptedMnemonic, mnemonic, userId: investor?._id ,isFamilyMember:sendToFamilyMember});
    }

    const sendMailToFamilyMember = async () => {
        await familymemberServices.sendMailToFamilyMember({ data: user.walletInfo.publicAddress + "|" + encryptedMnemonic, mnemonic, userId: user._id });
    }
   
    
    return (
        <ModalComponent slotProps={{
            root: {
                ref: modelRef
            }
        }}
            open={open}
            handleClose={customHandleClose}
            title={"Complete Account Setup"} PaperProps={{ sx: { overflow: "auto", minHeight: 'auto', minWidth: { md: 684, xs: "auto" } } }}>
            <Backdrop open={openProgressBar} sx={{ color: "#ddd", zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Box
                    sx={{
                        position: "absolute",
                        inset: 0,
                        zIndex: (theme) => theme.zIndex.modal + 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <CircularProgress
                        size={75}
                        thickness={4}

                    />
                </Box>
            </Backdrop>
            <Stepper currentStep={currentStep} />
            {
                animating &&
                <Box sx={{ width: 150, margin: "auto", pt: 20 }}>
                    <Lottie onComplete={() => {
                        setAnimating(false);
                    }} animationData={check} loop={false} />
                </Box>

            }
            {
                currentStep === 0 && !animating ?
                    <FamilyMemberRegister setStep={setStep} user={user} enqueueSnackbar={enqueueSnackbar} dispatch={dispatch} setAnimating={setAnimating} isIndian={isIndian} setIsIndian={setIsIndian} handleClose={handleClose} setFamilymemberId={setFamilymemberId} currentStep={currentStep} setUser={setUser} familymemberId={familymemberId} getallFamilyMember={getallFamilyMember}/>
                    // <Typography>personalDeatails</Typography>
                    :
                    currentStep === 1 && !animating ?
                        !manualKyc?<AutoKYCForm setIsManual={setManualKyc} setStep = { setStep } user = { user } otp = { otp } refId = { refId } setRefId = { setRefId } setOtp = { setOtp } otpForm = { otpForm } setOtpForm = { setOtpForm } aadhaar = { aadhaar } setAadhaar = { setAadhaar } openProgressBar = { openProgressBar } setOpenProgressBar = { setOpenProgressBar } enqueueSnackbar = { enqueueSnackbar } dispatch = { dispatch } handleClose = { handleClose } setAnimating = { setAnimating } familymemberId={familymemberId} setUser={setUser} currentStep={currentStep} getallFamilyMember={getallFamilyMember}/>:
                        <ManualKYCForm setIsManual={setManualKyc} setStep={setStep} user={user} otp={otp} refId={refId} setRefId={setRefId} setOtp={setOtp} otpForm={otpForm} setOtpForm={setOtpForm} aadhaar={aadhaar} setAadhaar={setAadhaar} openProgressBar={openProgressBar} setOpenProgressBar={setOpenProgressBar} enqueueSnackbar={enqueueSnackbar} dispatch={dispatch} handleClose={handleClose} setAnimating={setAnimating} familymemberId={familymemberId} setUser={setUser} getallFamilyMember={getallFamilyMember}/>
            

                    // <PersonalInfo setStep={setStep} user={user} enqueueSnackbar={enqueueSnackbar} dispatch={dispatch} setAnimating={setAnimating} isIndian={isIndian} setIsIndian={setIsIndian} handleClose={handleClose} />
            :
           
                        currentStep === 2 && !animating ?
                            <BlockchainAcc mnemonic={mnemonic} sendMail={sendMail} setSendMail={setSendMail} setEncryptedMnemonic={setEncryptedMnemonic} setmnemonic={setmnemonic} setStep={setStep} user={user} setBlockchainAccountCreated={setBlockchainAccountCreated} setBlockchainAccountDownload={setBlockchainAccountDownload} enqueueSnackbar={enqueueSnackbar} dispatch={dispatch} customHandleClose={customHandleClose} setAnimating={setAnimating} dltName={dltName} setDltName={setDltName} setUser={setUser} familyMemberId={familymemberId} sendToFamilyMember={sendToFamilyMember} setSendToFamilyMember={setSendToFamilyMember} getallFamilyMember={getallFamilyMember} publicAddress={publicAddress}setPublicAddress={setPublicAddress}/>
                            : ""
            }
        </ModalComponent>
    )
}

const Stepper = ({ currentStep }) => {
    const steps = ["Family Member Details", "Complete KYC", "Create Your Digital Assets Account"];
    return (
        <Box mt={5}>
            <Stack direction={"row"} spacing={4}>
                {
                    steps.map((e, i) => {
                        return (
                            <Stack direction={"column"}>
                                <Box sx={{
                                    width: "100%",
                                    position: "relative"
                                }}>
                                    <Box sx={{
                                        width: 24, height: 24,
                                        borderRadius: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        bgcolor: currentStep > i ? "success.main" : "success.light",
                                        position: "relative", zIndex: 1
                                    }}>
                                        {
                                            currentStep === i ?
                                                <Box sx={{
                                                    width: 12,
                                                    height: 12,
                                                    bgcolor: "success.main",
                                                    borderRadius: "100%",
                                                }}>
                                                </Box>
                                                :
                                                currentStep > i ?
                                                    <CustomIcon name="check" sx={{ bgcolor: "#FFF", width: 12, height: 12, }} />
                                                    : ""
                                        }
                                    </Box>
                                    {
                                        steps.length === i + 1 ? "" :
                                            <Box sx={{
                                                width: "calc(100% + 32px)",
                                                height: 4,
                                                bgcolor: currentStep > i ? "success.main" : "success.light",
                                                position: "absolute",
                                                top: "50%",
                                                left: 0,
                                                transform: "translateY(-50%)",
                                                zIndex: 0
                                            }}>
                                            </Box>
                                    }
                                </Box>
                                <Box sx={{ minWidth: 130, display: { xs: currentStep === i ? "block" : "none", md: "block" } }}>
                                    <Typography variant="subtitle1" sx={{ fontSize: 12, mt: { md: 3, xs: 2 } }}>Step {i + 1}</Typography>
                                    <Typography variant="body2" sx={{ mt: 0 }}>{e}</Typography>
                                </Box>
                            </Stack>
                        )
                    })
                }
                {/* <Stack direction={"column"}>
                    <Box sx={{width:24,height:24,borderRadius:"100%",bgcolor:"success.main"}}></Box>
                    <Box sx={{minWidth:130}}>
                        <Typography variant="subtitle1" sx={{fontSize:12,mt:3}}>Step 2</Typography>
                        <Typography variant="body2" sx={{mt:0}}>Complete KYC</Typography>
                    </Box>
                </Stack> */}
            </Stack>
        </Box>
    )
}
