import { Box, Button, Card, CardContent, Divider, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Tabs from "../../components/tabs/tabs";
import CustomIcon from "../../components/customIcon";
import MobileBottomTab from "../../components/tabs/mobileBottomTab";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TransactionsTable } from "../../components/tables";
import userServices from "../../services/userServices";
import store from "../../redux/store";
import CanceledTransactions from "../portfolio/CanceledTransactions";

export default function Transactions({ title, status, areTransactionsThere, setAreTransactionsThere }){
    const { watchlist } = useSelector(state=>state.user);
    const [transactionsDetails,setData] = useState([]);

    const investorId = store.getState().user.data._id;

    const getTransactionDetails = async(investorId) =>{        
        let res = await userServices.getATransactionDetails({investorId,status:status});
        
        if(res && res.success && res.data?.length >0){
            if(status){
                setAreTransactionsThere(true)
            }
            res.data[0].url = res.url;
            setData(res.data)
        }
    }

    useEffect(()=>{
        getTransactionDetails(investorId);
    },[]);
    

    const tabs = [
        {
            name:title || "Active Transactions",
            tab:<TransactionsTable transactionsDetails={transactionsDetails} getTransactionDetails={getTransactionDetails} title={title}/>
        },
        {
            name:title || "Cancelled Transactions",
            tab:<CanceledTransactions title={title}/>
        }
    ];

    const [activeTab,setActiveTab] = useState(0);
    const handleTabClick = (index) =>{
        setActiveTab(index);
    }
    return(
        <Box pb={{xl:"0px",lg:"0px",md:"0px",xs:"77px"}}>
                <Card 
                sx={{mt:{md:5,xs:3}}}
                >
                <Stack>

                    <Box sx={{padding:"10px"}}>
                    { transactionsDetails && 
                        <Tabs activeTab={activeTab} tabs={tabs} onClick={handleTabClick} />
                    }
                    <Divider sx={{
                            width:"calc(100% + 80px)",
                        }} />
                    </Box>
                    
                    <Box >
                        {
                            
                             tabs[activeTab]?.tab
                        }
                    </Box>
                </Stack>
                </Card>

            {/* } */}

            <MobileBottomTab />
        </Box>
    )
}

const EmptyTableState = ({showAction=false,title,subtitle}) =>{
    const navigate = useNavigate();
    return(
        <Box
        sx={{
            flex:1,
            width:"100%",
            // bgcolor:"red"
        }}
    >
        <Stack height={"100%"} direction={"column"} alignItems={"center"} justifyContent={"center"}>
            <Box 
                sx={{
                    width:{md:160,xs:88},height:{md:160,xs:88},
                    bgcolor:"primary.light",
                    borderRadius:"100%",
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center",
                    mt:{md:0,xs:3}
                }}
            >
                <CustomIcon name="file-document" sx={{bgcolor:"primary.main",width:{md:72,xs:32},height:{md:72,xs:32},}} />
            </Box>
            <Typography sx={{fontSize:"28px",fontWeight:500,mt:{md:4,xs:3},mb:{md:1.5,xs:0.5}}}>{title}</Typography>
            <Typography variant="subtitle1" sx={{fontSize:"16px",fontWeight:300,mb:{md:3,xs:2}}}>{subtitle}</Typography>
            {showAction && <Button onClick={()=>navigate("/dashboard")} sx={{mb:{md:0,xs:3}}} variant="contained">Explore Properties to invest</Button>}
        </Stack>
    </Box>
    )
}