import { server } from "../utils/server";

const createFamilyMember = (data) =>{
    return server.post("/familyMember/auth/createFamilyMember",data)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err);
        return null;
    });
}
const familymemberAddharCard=(data)=>{
    return server.post("/familyMember/auth/addharCard",data)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err);
        return null;;
        return null;
    });
}
const familymemberAddharCardVerification=(data)=>{
    return server.post(`/familyMember/auth/addharCardVerification`,data)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err);
        return null;
    });
}
const familymemberCompleteKyc=(data)=>{
    return server.post(`/familyMember/auth/completeKyc`,data)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err);
        return null;
    });
}

const familymemberManualKyc=(data)=>{
    return server.post(`/familyMember/auth/completeManualKyc`,data)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err);
        return null;
    });
}

const createBlockChainAcc = (data) =>{
    return server.post("/familyMember/createWallet",data)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err);
        return null;
    });
}

const updateFamilyMemberDLTCreationStatus = (data) =>{
    return server.post("/familyMember/updateFamilyMemberDLTCreationStatus",data)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err);
        return null;
    });
}

const updateFamilyMemberPrimaryDLTAccount = (data) =>{
    return server.post("/familyMember/updateFamilyMemberPrimaryDLTAccount",data)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err);
        return null;
    });
}
const removeFamilyMemberDLTAccount = (data) =>{
    return server.post("/familyMember/removeFamilyMemberDLTAccount",data)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err);
        return null;
    });
}

const sendMailToFamilyMember = (data) =>{
    return server.post("/algorand/auth/familyMemberCredentialMail",data)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err);
        return null;
    }); 
}
const familyMemberAccordingToInvester=(id)=>{
    return server.get(`/familyMember/auth/getallFamilyMember/${id}`)
    .then((res)=>{
        return res.data;
    })
    
}
const getDltWallet=(id)=>{
    return server.get(`/familyMember/getDltWallet/${id}`)
    .then((res)=>{
        return res.data;
    })
    
}
const familyMemberById=(id)=>{
    return server.get(`/familyMember/auth/getFamilyMember/${id}`)
    .then((res)=>{
        return res.data;
    })
    
}

export default {
    createFamilyMember,
    familymemberAddharCard,
    familymemberAddharCardVerification,
    familymemberCompleteKyc,
    familymemberManualKyc,
    createBlockChainAcc,
    sendMailToFamilyMember,
    familyMemberAccordingToInvester,
    updateFamilyMemberDLTCreationStatus,
    updateFamilyMemberPrimaryDLTAccount,
    removeFamilyMemberDLTAccount,
    familyMemberById,
    getDltWallet
}