import React, { useEffect, useState } from "react";
import transactionServices from "../../services/transactionServices";
import { useSelector } from "react-redux";
import { convertToIndianFormat, getTitle } from "../../utils/functions";
import moment from "moment";
import { AmountPaidCommited, DetailButton, TableRow, TableRowImg, TableRowMultilineInfo, TxnRefNumber } from "../../components/miniComponents";
import CustomTable from "../../components/customTable";
import { AllTranSaction, EmptyTableState, FamilyMemberTransaction, HUFTransaction, PersonalTransaction } from "../../components/tables";
import DataGridTable from "../../components/DataGridTabel";
import { Box, Divider, Stack, Typography } from "@mui/material";
import Tabs from "../../components/tabs/tabs";
import MobileBottomTab from "../../components/tabs/mobileBottomTab";

const CanceledTransactions = ({title}) => {
    const user = useSelector(state => state.user.data);
    const [data, setData] = useState([]);
    const [activeTab,setActiveTab] = useState(0);
    const [paymentAccount,setPaymentAccount]=useState(false)
    const handleTabClick = (index) =>{
        setActiveTab(index);
    }
    useEffect(() => {
        getMyInvestments();
    }, [])

    const getMyInvestments = async () => {
        let reqData = {
            investorId: user._id,
            statusArray: ['REJECTED', 'EXPIRED']
        };

        const res = await transactionServices.getTransactionByLisitngAndUser(reqData);

        if (res && res.success && res.data?.length > 0) {

            let tempData = [];
            for (let e of res.data) {

                let temp = {
                    propertyInfo: [
                        getTitle(e.listing) + "-" + e.project.projectName || e.project.projectName,
                        res.url + (e?.listingImages[0]?.docUrl || ""),
                        e.project.projectName,
                        e.listing.propertyCode,
                    ],
                    imgUrl: res.url + (e?.listingImages[0]?.docUrl || ""),
                    projectName: `${getTitle(e.listing)}-${e.project.projectName}`,
                    propertyId: e.listingId,
                    frax: e.noOfFracs,
                    investAmm: `₹ ${e.amount}` || 0,
                    pendingAmount: `₹ ${e.pendingAmt}` || 0,
                    date: moment(e.created_at).format("DD/MM/YYYY") || "12/12/2000",
                    uDate: moment(e.created_at).format("DD/MM/YYYY") || "12/12/2000",
                    percentInvested: `${e.propertyPercent} %`,
                    status: e.status,
                    listingId: e.listingId,
                    projectId: e.projectId,
                    _id: e._id,
                    transactionId: e.transactionId,
                    saleDeed: res.url + (e?.listing?.registrationCertificate[0]?.docUrl || ""),
                    rejectReason: e.rejectReason || 'No reason available',
                    isHuf: e.isHuf,
                    familyMemberId: e.familyMemberId,
                    username: e.familyMember?.username,
                    relation: e.familyMemberInvestor?.relation
                };
                tempData.push(temp);
            }

            setData(tempData);
        } else {

        }
    };
    const huftransaction = data.filter(transaction => transaction.isHuf === true);

    const familymemberTransaction = data.filter(data =>
        'familyMemberId' in data && data.familyMemberId !== null && data.familyMemberId !== undefined
    );
    const personalTransaction = data.filter(transaction => transaction.isHuf === false && transaction.familyMemberId == null && transaction.familyMemberId == undefined);
    
    const table = [
        {
            field: 'imgUrl',
            headerName: 'Property Image',
            flex: 1,
            renderCell: (params) => {
                return <><div style={{ display: "flex", justifyContent: "center", width: "100%", height: "100%", padding: "2px" }}><img src={params.row.imgUrl} alt="Property" style={{ width: '100px', height: 'auto', borderRadius: "4px" }} /></div>
                </>;
            }
        },
        paymentAccount?{
            field: "isHuf", headerName: "Payment Account", flex: 1.5, alignItems: "center", textAlign: "center",
            renderCell: (params) => {
                
                if (params.row.isHuf) {
                    return <Typography variant="body3">HUF</Typography>
                } else if (params.row.familyMemberId) {
                    return <Typography variant="body3">{params.row?.username ? `${params.row?.username}(${params.row?.relation})` : "Family"}</Typography>
                }
                else {
                    return <Typography variant="body3">Personal</Typography>
                }
            }
        }:null,
        { field: 'projectName', headerName: 'Property Name', flex: 1 },
        { field: 'investAmm', headerName: 'Amount Commited', flex: 1 },
        { field: 'frax', headerName: 'No of FRAX', flex: 1 },
        { field: 'percentInvested', headerName: '% Ownership', flex: 1 },
        { field: 'rejectReason', headerName: 'Reject Reason', flex: 2 },
    ].filter(Boolean)

const tabs = [
        {
            name: title || "All Transactions",
            tab: <AllTranSaction data={data} table={table} setPaymentAccount={setPaymentAccount}/>
        },
        personalTransaction.length> 0 ? {
            name: title || "Personal",
            tab: <PersonalTransaction data={personalTransaction} table={table} setPaymentAccount={setPaymentAccount} />
        } : null,
        familymemberTransaction.length > 0 ? {
            name: title || "Family Member",
            tab: <FamilyMemberTransaction data={familymemberTransaction} table={table} setPaymentAccount={setPaymentAccount}/>
        } : null,
        huftransaction.length > 0 ? {
            name: title || "HUF",
            tab: <HUFTransaction data={huftransaction} table={table} setPaymentAccount={setPaymentAccount}/>
        } : null
    ].filter(Boolean); 
    // const tableCells = [<TableRowImg />, <TableRowMultilineInfo />, <TableRow />, <TableRow />, <TableRow />, <TableRow />];
    if (data.length === 0)
        return (<EmptyTableState showAction={true} title={"No Properties"} subtitle={"You've not made any investments yet."} />)
    // eslint-disable-next-line
    return (
        <>
 {(huftransaction.length == 0 && familymemberTransaction.length == 0) && (
            <DataGridTable initialColumns={table} initialRows={data} />
        )}
        {huftransaction.length>0||familymemberTransaction.length>0&&<Box pb={{xl:"0px",lg:"0px",md:"0px",xs:"77px"}}>
                                            <Box 
                                            sx={{mt:{md:2,xs:2}}}
                                            >
                                            <Stack>
                            
                                                <Box mb={3}  >
                                                    <Box sx={{mx:"46px"}} >
                                                { data && 
                                                    <Tabs activeTab={activeTab} tabs={tabs} onClick={handleTabClick} />
                                                }
                                                </Box>
                                                <Divider sx={{
                                                        width:"calc(100% + 80px)",
                                                        
                                                    }} />
                                                </Box>
                                                
                                                
                                                <Box >
                                                    {
                                                        
                                                         tabs[activeTab]?.tab
                                                    }
                                                </Box>
                                            </Stack>
                                            </Box>
                            
                                        {/* } */}
                            
                                        <MobileBottomTab />
                                    </Box>}
        </>
    )
};

export default CanceledTransactions;