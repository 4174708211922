import React, { useState, useEffect } from 'react';
import { Box, Skeleton } from "@mui/material";
import { DataGrid, GridToolbar, GridToolbarExport, GridToolbarContainer } from "@mui/x-data-grid";

const DataGridTable = ({ initialRows, initialColumns, initialPageSize,initialRowHeight }) => {
    const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState([]);


    useEffect(() => {

        const fetchData = async () => {
            setTimeout(() => {
                setRows(initialRows);
                setLoading(false);
            }, 100);
        };

        fetchData();
    }, [initialRows]);
    const renderSkeletonRows = () => {
        return Array(5)
            .fill(0)
            .map((_, index) => (
                <div key={index} style={{ display: 'flex', padding: '10px', marginBottom: '10px' }}>
                    {initialColumns.map((col, colIndex) => (
                        <Skeleton
                            key={colIndex}
                            variant="rectangular"
                            width={200}
                            height={40}
                            style={{ margin: '0 0' }}
                        />
                    ))}
                </div>
            ));
    };

    // function CustomToolbar() {
    //     return (
    //       <GridToolbarContainer>
    //         <GridToolbarExport />
    //       </GridToolbarContainer>
    //     );
    //   }
    const rowsWithId = rows?.map((row, index) => ({
        ...row,
        id: index,
    }));

    return (
        <Box sx={{ marginTop: "20px" }}>
            {loading ? (
                <Box sx={{ borderRadius: "20px", boxShadow: "0px 0px 8px #cccccc", padding: "10px", maxHeight: '500px' }}>
                    {renderSkeletonRows()}
                </Box>
            ) : (
                <DataGrid
                    sx={{
                        borderRadius: 0.5,
                        boxShadow: 1,
                        border: '1px solid #dde7eb80',
                        "& .MuiDataGrid-cell:focus": {
                            outline: "none !important", // Disable focus outline
                        },
                        "& .MuiDataGrid-row:nth-of-type(even)": {
                            backgroundColor: "#f9f9f9", // Even row background color
                        },
                        "& .MuiDataGrid-row:nth-of-type(odd)": {
                            backgroundColor: "#ffffff", // Odd row background color
                        },
                        "& .MuiDataGrid-columnHeaderTitleContainer": {
                            justifyContent: "center", // Center header text
                            // backgroundColor:"black"
                        },
                        "& .MuiDataGrid-cell--textLeft": {
                            textAlign: "center",
                        },
                        "& .MuiDataGrid-container--top [role=row]": {
                            backgroundColor: "#ffdddb"
                        }

                    }}
                    rows={rowsWithId}
                    columns={initialColumns}

                    initialState={{

                        pagination: { paginationModel: { pageSize: initialPageSize || 10 } },
                    }}
                    pageSizeOptions={[5, 10, 25]}

                    disableSelectionOnClick
                    components={{ Toolbar: GridToolbar }}
                    rowHeight={initialRowHeight}
                />
            )}
        </Box>
    );
};

export default DataGridTable;