import { Box, Button, Stack, Typography, Grid, CircularProgress, Backdrop } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import { LoadingButton } from "@mui/lab"
import React, { useEffect, useRef, useState } from "react";
// import ModalComponent from "./modalComponent";
// import authServices from "../../services/authServices";
import { useDispatch, useSelector } from 'react-redux';
// import CustomIcon from "../customIcon";
import { useSnackbar } from "notistack";
// import { saveUser } from "../../redux/reducers/userSlice";
import AreYouSureModal from "../../../../components/modals/areYouSureModal";
import 'react-international-phone/style.css';
import authServices from "../../../../services/authServices";
import ModalComponent from "../../../../components/modals/modalComponent";
import { saveUser } from "../../../../redux/reducers/userSlice";
import CustomIcon from "../../../../components/customIcon";
import FormField from "../../../../components/form/formField";
import hufService from "../../../../services/hufService";
import familymemberServices from "../../../../services/familymemberServices";


export default function FamilyMemberCreateDLTModel({ open, setOpen, familymemberAccount, getallFamilyMember }) {

    const [blockchainAccountDownload, setBlockchainAccountDownload] = useState(false);
    const [blockchainAccountCreated, setBlockchainAccountCreated] = useState(false);
    const [animating, setAnimating] = useState(false);
    const [mnemonic, setmnemonic] = useState();
    const dispatch = useDispatch();
    const [openProgressBar, setOpenProgressBar] = useState(false);
    const [encryptedMnemonic, setEncryptedMnemonic] = useState("");
    const [sendToFamilyMember, setSendToFamilyMember] = useState(false)
    const [sendMail, setSendMail] = useState(true);
    const [publicAddress,setPublicAddress]=useState();
    const modelRef = useRef();
    const { enqueueSnackbar } = useSnackbar();
    const user = useSelector(state => state.user.data)

    useEffect(() => {
        if (familymemberAccount?.status !== "KYC_APPROVED" && familymemberAccount?.status !== "APPROVED") {
            setOpen(false);
        }
    }, [familymemberAccount?.status]);

    useEffect(() => {
        if (animating) {
            scrollToTop();
        }
    }, [animating])

    const customHandleClose = (newDltAccountAddress) => {
        if (!blockchainAccountCreated || blockchainAccountDownload) {
            setOpen(false);
            setBlockchainAccountCreated(false);
        }
        else enqueueSnackbar("Please download key details before closing!", { variant: "error", anchorOrigin: { horizontal: "right", vertical: "top" }, autoHideDuration: 5000 })
        if (sendMail && blockchainAccountDownload) sendMnemonicThroughMail();
        if (sendToFamilyMember && blockchainAccountDownload) sendMailToFamilyMember();
    }

    const scrollToTop = () => {
        if (modelRef.current) {
            let modalBody = modelRef.current.querySelector('.MuiDialog-container')
            if (modalBody) {
                modalBody.scroll({
                    top: 0,
                    behavior: 'smooth'
                });
            }
        }
    }

    const sendMnemonicThroughMail = async () => {
        await authServices.sendMnemonicThroughMail({ data: user.walletInfo.publicAddress + "|" + encryptedMnemonic, mnemonic, userId: user?._id, isFamilyMember: sendToFamilyMember });
    }

    const sendMailToFamilyMember = async () => {
        await familymemberServices.sendMailToFamilyMember({ data: user.walletInfo.publicAddress + "|" + encryptedMnemonic, mnemonic, userId: familymemberAccount._id });
    }
    return (
        <ModalComponent
            slotProps={{
                root: {
                    ref: modelRef
                }
            }}
            open={open}
            handleClose={customHandleClose}
            PaperProps={{ sx: { textAlign: "center", overflow: "auto", minHeight: { md: 500 }, minWidth: { md: 584, xs: "auto" } } }}>
            <Backdrop open={openProgressBar} sx={{ color: "#ddd", zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <CircularProgress
                    size={75}
                    thickness={4}
                    sx={{
                        position: "fixed",
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)",
                        zIndex: 2
                    }}
                    style={{ color: "#fff" }}
                />
            </Backdrop>
            <BlockchainAcc mnemonic={mnemonic} sendMail={sendMail} setSendMail={setSendMail} setEncryptedMnemonic={setEncryptedMnemonic} setmnemonic={setmnemonic} familymemberAccount={familymemberAccount} blockchainAccountCreated={blockchainAccountCreated} setBlockchainAccountCreated={setBlockchainAccountCreated} setBlockchainAccountDownload={setBlockchainAccountDownload} enqueueSnackbar={enqueueSnackbar} dispatch={dispatch} customHandleClose={customHandleClose} setAnimating={setAnimating} getHufDetails={() => getallFamilyMember()} sendToFamilyMember={sendToFamilyMember} setSendToFamilyMember={setSendToFamilyMember} publicAddress={publicAddress}setPublicAddress={setPublicAddress}/>
        </ModalComponent>
    )
}

const BlockchainAcc = ({ enqueueSnackbar, dispatch, customHandleClose, setAnimating, sendMail, setSendMail, mnemonic, setmnemonic, setBlockchainAccountDownload, blockchainAccountCreated, setBlockchainAccountCreated, setEncryptedMnemonic, getHufDetails, familymemberAccount, sendToFamilyMember, setSendToFamilyMember }) => {

    const [loading, setLoading] = useState(false);
    const [keyDownloadButtonTxt, setKeyDownloadButtonTxt] = useState('Download Key Details');
    const [publicAddress, setPublicAddress] = useState("");
    const [dltName, setDltName] = useState('')
    const [confirmModal, setConfirmModal] = useState('')

    const createDlt = () => {
        if (dltName === "") {
            setConfirmModal(true)
            return
        }
        createBlockChainAcc()
    }

    const createBlockChainAcc = async () => {
        let data = {
            "familyMemberId": familymemberAccount._id,
            dltName: dltName
        }

        if (familymemberAccount.walletInfo?.publicAddress && familymemberAccount.walletInfo?.isAllowedToCreateDLT === 'APPROVED') {
            data.isSecondaryAccount = true;
        }
        setLoading(true);
        let res = await familymemberServices.createBlockChainAcc(data);
        setLoading(false);

        if (res && res.success) {
            setAnimating(true);
            setmnemonic(res?.mnemonic);
            setPublicAddress(res?.publicAddress);
            setBlockchainAccountCreated(true);
            // dispatch(saveUser(res.data));
        }
        else enqueueSnackbar(res?.data?.message || "Something went wrong!!!", { variant: "error", autoHideDuration: 3000 });
    }

    const copyText = (text) => {
        navigator.clipboard.writeText(text);
        enqueueSnackbar("Copied to clipboard.", { variant: "success" })
    }

    const downloadTxtFile = async () => {
        // eslint-disable-next-line
        const element = document.createElement("a");
        // eslint-disable-next-line
        const res = await authServices.encryptCredentials({ mnemonic });
        if (res && res.success) {
            setEncryptedMnemonic(res.data);
            const file = new Blob([publicAddress + "|" + res.data], { type: 'text/plain' });
            element.href = URL.createObjectURL(file);
            element.download = `DIGITAL_ASSET_ACCOUNT_${publicAddress}.txt`;
            // document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
            setBlockchainAccountDownload(true);
            getHufDetails()
            sessionStorage.setItem('reduxState', JSON.stringify({
                ...JSON.parse(sessionStorage.getItem('reduxState') || '{}'),
                user: {
                    ...JSON.parse(sessionStorage.getItem('reduxState') || '{}')?.user,
                    data: {
                        ...JSON.parse(sessionStorage.getItem('reduxState') || '{}')?.user?.data,
                        isHufHead: true
                    }
                }
            }));
        }
        else {
            enqueueSnackbar("Something Went Wrong, Please Try Again!", { variant: "error" });
        }
        setKeyDownloadButtonTxt('Key Details Downloaded')
    }


    return (
        <Box mt={0}>
            <AreYouSureModal message={"Do you want to save without wallet name?"} setOpen={setConfirmModal} open={confirmModal} handleYes={() => { createBlockChainAcc() }} />
            {
                (blockchainAccountCreated == true) ?
                    <Stack alignItems={"center"} mt={{ md: 0 }} spacing={{ md: 2, xs: 3 }}>
                        <Typography variant="h4">Your New Digital Assets Account Created</Typography>
                        <Box
                            sx={{
                                width: { md: 112, xs: 88 }, height: { md: 112, xs: 88 },
                                bgcolor: "success.light",
                                borderRadius: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                mt: { md: 0, xs: 3 }
                            }}
                        >
                            <CustomIcon name="check" sx={{ bgcolor: "success.main", width: { md: 50, xs: 32 }, height: { md: 50, xs: 32 }, }} />
                        </Box>
                        <Stack spacing={3} width={"100%"} alignItems={{ md: "center" }}>
                            <Stack alignItems={"center"} spacing={1} sx={{ maxWidth: 508 }} textAlign={"center"}>
                                <Typography variant="subtitle2" sx={{ color: "#000", fontWeight: 400 }}>
                                    Your digital account is successfully created.
                                    <b> PLEASE SAVE AND KEEP SECURE THE BELOW PRIVATE KEY (PASSWORD) OF YOUR BLOCKCHAIN ACCOUNT.</b></Typography>
                            </Stack>
                            <Box px={{ md: 2 }}>
                                <Box
                                    sx={{
                                        p: 3,
                                        borderRadius: "6px",
                                        border: "1px solid #ccc",
                                    }}
                                >
                                    <Stack spacing={1.5}>
                                        <Stack spacing={0.5}>
                                            <Typography variant="subtitle2" sx={{ color: "black", fontWeight: 400 }}>Account ID  (Public Key)</Typography>
                                            <Stack direction={"row"} px={3} py={1.5} justifyContent={"space-between"} sx={{ border: "1px solid #E1E1E1", borderRadius: 1 }}>
                                                <Typography variant="body2" sx={{ textOverflow: "ellipsis", width: { md: 256, xs: "100%" }, whiteSpace: "nowrap", overflow: "hidden" }}>{publicAddress}</Typography>
                                                <Box onClick={() => copyText(publicAddress)} sx={{ cursor: "pointer", }}>
                                                    <CustomIcon name="copy" />
                                                </Box>
                                            </Stack>
                                        </Stack>
                                        <Stack spacing={0.5}>
                                            <Typography variant="subtitle2" sx={{ color: "black", fontWeight: 400 }}>Password (Private Key)</Typography>
                                            <Stack spacing={0.5} direction={"row"} px={3} py={1.5} justifyContent={"space-between"} sx={{ border: "1px solid #E1E1E1", borderRadius: 1 }}>
                                                <Typography variant="body2" sx={{ textOverflow: "ellipsis", width: { md: 256, xs: "100%" }, whiteSpace: "nowrap", overflow: "hidden" }}>{mnemonic}</Typography>
                                                <Box onClick={() => copyText(mnemonic)} sx={{ cursor: "pointer", }}>
                                                    <CustomIcon name="copy" />
                                                </Box>
                                            </Stack>
                                        </Stack>
                                        <Button onClick={downloadTxtFile} variant="contained" sx={{ mb: 1 }}>{keyDownloadButtonTxt}</Button>
                                    </Stack>
                                </Box>
                            </Box>

                            <Box sx={{
                                maxWidth: { md: 408, xs: "100%" },
                                bgcolor: "warning.light",
                                px: "10px",
                                py: 1,
                                borderRadius: 0.5
                            }}>
                                <Stack direction={'row'} rowGap={2}>
                                    <Checkbox
                                        checked={sendMail}
                                        onClick={() => setSendMail(!sendMail)}
                                        color="primary"

                                    />
                                    <Typography variant="subtitle2" sx={{ color: "#000", fontWeight: 400 }}>Send key detail file to my email. Keep this file secure as it is required to access DLT account.</Typography>
                                </Stack>
                                <Stack direction={'row'} rowGap={2}>
                                    <Checkbox
                                        checked={sendToFamilyMember}
                                        onClick={() => setSendToFamilyMember(!sendToFamilyMember)}
                                        color="primary"
                                        defaultChecked={false}
                                    />
                                    <Typography variant="subtitle2" sx={{ color: "#000", fontWeight: 400 }}>Send key detail file to family member's email. Keep this file secure as it can be used to access DLT account.</Typography>
                                </Stack>
                            </Box>
                            <Stack px={{ md: 6 }}>
                                <Button onClick={() => customHandleClose(publicAddress)} variant="outlined" sx={{ mb: 1 }}>Complete Account Creation</Button>
                            </Stack>
                            <Box
                                sx={{
                                    maxWidth: 408,
                                    bgcolor: "warning.light",
                                    px: "10px",
                                    py: 1,
                                    borderRadius: 0.5,
                                }}
                            >
                                <Stack direction={"row"} spacing={0.5}>
                                    <CustomIcon name="warning" sx={{ bgcolor: "warning.main", width: 80, height: 20, }} />
                                    <Typography variant="subtitle2" sx={{ color: "#000", fontWeight: 400 }}>
                                        Kindly note the private key is your own secure access key for your account.
                                        RealX does not store your private key and therefore will not have access to your account or ability to recover this key.
                                    </Typography>
                                </Stack>
                            </Box>
                        </Stack>
                    </Stack>
                    :
                    <Stack alignItems={"center"} mt={{ md: 0 }} spacing={{ md: 2, xs: 3 }} >
                        <Typography variant="h4">Complete Family Member Digital Account Setup</Typography>
                        <Box
                            sx={{
                                width: { md: 132, xs: 88 }, height: { md: 132, xs: 88 },
                                bgcolor: "primary.light",
                                borderRadius: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                mt: { md: 0, xs: 3 }
                            }}
                        >
                            <CustomIcon name="blockchain" sx={{ bgcolor: "primary.main", width: { md: 70, xs: 32 }, height: { md: 70, xs: 32 }, }} />
                        </Box>
                        <Stack mt={{ md: 1 }} spacing={2.5} alignItems={'center'}>
                            <Stack alignItems={"center"} spacing={1} sx={{ maxWidth: 408 }} textAlign={"center"}>
                                <Typography variant="h5" sx={{ lineHeight: "24px" }}>Create A New Digital Assets Account</Typography>
                                <Typography variant="subtitle1" sx={{ color: "#000", fontWeight: 400 }}>You'll need to create a new account to receive the FRAX for your Future Investments.</Typography>
                            </Stack>
                            <Stack px={{ md: 6 }}>
                                <FormField label={"Enter Nick Name"} inputProps={{
                                    placeholder: "Secondary Account",
                                    type: "text",
                                    value: dltName,
                                    onChange: (e) => {
                                        setDltName(e.target.value)
                                    },
                                }} />
                            </Stack>
                            <Stack px={{ md: 6 }}>
                                <LoadingButton loading={loading} onClick={createDlt} variant="contained" sx={{ mb: 1 }}>Create New Blockchain Account</LoadingButton>
                            </Stack>
                            <Box
                                sx={{
                                    maxWidth: 408,
                                    bgcolor: "warning.light",
                                    px: "10px",
                                    py: 1,
                                    borderRadius: 0.5,
                                }}
                            >
                                <Stack direction={"row"} spacing={0.5}>
                                    <CustomIcon name="warning" sx={{ bgcolor: "warning.main", width: 80, height: 20, }} />
                                    <Typography variant="subtitle1" sx={{ color: "#000", fontWeight: 400 }}>
                                        Once your account is created, you'll see a private key of your digital assets account.
                                        <span style={{ fontWeight: 600 }}> Please save this key</span> as this is the password to access your digital assets account.
                                        This key is only known to you and RealX does not store or has any knowledge of it.
                                    </Typography>
                                </Stack>
                            </Box>
                        </Stack>
                    </Stack>
            }
        </Box>
    )
}