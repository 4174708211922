import { Box, Button, Stack, Typography, CircularProgress } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { useSnackbar } from "notistack";
import moment from "moment";
import FormField from "../../components/form/formField";
import FormSelect from "../../components/form/formSelect";
import { saveUser } from "../../redux/reducers/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { FormDatePicker } from "../../components/form/datePicker";
import familymemberServices from "../../services/familymemberServices";

export const FamilyMemberRegister = ({  enqueueSnackbar, dispatch, setAnimating,setFamilymemberId,setStep ,currentStep,getallFamilyMember}) => {
   
    const personalInfoSchemaThroughSocialSignIn = Yup.object().shape({
        username: Yup.string().required("Username is required."),
        emailId: Yup.string().email("Please enter valid email.").notRequired(),
        dob: Yup.string().required("Date of birth is required."),
        relation: Yup.string().required("Relation is required."),
    });

    const [isSubmitting, setSubmitting] = useState(false);
    const investor = useSelector((state) => state.user.data);
  
    
    const relation = [
        { title: "Parent", value: "Parent" },
        { title: "Spouse", value: "Spouse" },
        { title: "Sibling", value: "Sibling" },
        { title: "Child", value: "Child" },
    ];

    const formik = useFormik({
        initialValues: {
            username: "",
            emailId: "",
            dob: moment().subtract(18, "years"),
            relation: "",
        },
        validationSchema: personalInfoSchemaThroughSocialSignIn,
        onSubmit: (values) => {
            saveDetails(values);
        },
    });

    const { errors, touched, handleSubmit, getFieldProps, values, setFieldValue } = formik;

    const saveDetails = async (values) => {
        let data = {
            username: values.username,
            dob: values.dob.unix(),
            emailId:values.emailId,
            relation: values.relation,
            investerId: investor._id,
        };
         // Log the data you're sending to the backend
        setSubmitting(true);
        try {
        let res;
           if(data.emailId!=investor.emailId){
             res = await familymemberServices.createFamilyMember(data);
            setSubmitting(false);
            if (res && res.success) {
                enqueueSnackbar("Personal details submitted", { variant: "success" });
                setAnimating(true);
                setStep(currentStep + 1);
                setFamilymemberId(res.data?._id);
                getallFamilyMember()
                // dispatch(saveUser(res.data));
            } else {
                enqueueSnackbar(res?.message || "Something went wrong!", { variant: "error", autoHideDuration: 2000 });
            }
           }else{
            setSubmitting(false);
            enqueueSnackbar("Parant Email should not be equal to family member", {  variant: "error", autoHideDuration:2000 });
           }
           
        } catch (error) {
            setSubmitting(false);
            console.error("Error during submission:", error);
            enqueueSnackbar("An error occurred while submitting data", { variant: "error" });
        }
    };

    return (
        <Box mt={4}>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Typography variant="h4">Enter Personal Information</Typography>
                    <Stack spacing={3} mt={3}>
                        <Stack direction={{ md: "row", xs: "column" }} spacing={{ md: 3, xs: 1.5 }}>
                            <FormField
                                label={"How would you like to be called? *"}
                                inputProps={{
                                    placeholder: "username",
                                    ...getFieldProps("username"),
                                    error: Boolean(touched.username && errors.username),
                                    helperText: touched.username && errors.username,
                                }}
                            />
                            <FormSelect
                                label={"Relation*"}
                                options={relation}
                                props={{
                                    ...getFieldProps("relation"),
                                    error: Boolean(touched.relation && errors.relation),
                                    helperText: touched.relation && errors.relation,
                                }}
                            />
                        </Stack>
                        <Stack direction={{ md: "row", xs: "column" }} spacing={{ md: 3, xs: 1.5 }}>
                            <Box width={{ md: "50%", xs: "100%" }}>
                                <FormField
                                    label={"Email Address"}
                                    inputProps={{
                                        placeholder: "",
                                        ...getFieldProps("emailId"),
                                        error: Boolean(touched.emailId && errors.emailId),
                                        helperText: touched.emailId && errors.emailId,
                                    }}
                                />
                            </Box>
                            <Box sx={{ width: { md: "50%", sm: "100%" } }}>
                                <FormDatePicker
                                    sx={{ width: "100%" }}
                                    label="Date Of Birth *"
                                    disabledFuture={true}
                                    value={values.dob}
                                    onChange={(newValue) => setFieldValue("dob", newValue)}
                                />
                            </Box>
                        </Stack>
                    </Stack>
                    <LoadingButton
                        loading={isSubmitting}
                        type="submit"
                        variant="contained"
                        sx={{ mt: 5, width: { md: "auto", xs: "100%" } }}
                        loadingIndicator={<CircularProgress size={30} sx={{ color: "red" }} />}
                        disabled={isSubmitting}
                    >
                        Save And proceed
                    </LoadingButton>
                </Form>
            </FormikProvider>
        </Box>
    );
};
