import React, { useEffect, useState } from 'react'
import ModalComponent from './modalComponent'
import { Box, Button, Stack, Typography } from '@mui/material'
import Lottie from 'lottie-react';
import serverError from "../../animations/serverError.json"


export default function NoInternet({ open, handleClose }) {
    const [animatingWar, setAnimatingWar] = useState(false);
    useEffect(() => {
        if (open) {
            setAnimatingWar(true);
        }
    }, [open]);

    return (
        <ModalComponent open={open} handleClose={handleClose}>
            <Box sx={{ textAlign: 'center', borderRadius: 2, padding: "8px 8px" }}>
                <Stack sx={{ alignItems: 'center', justifyContent: 'center', borderRadius: 2, padding: 1, minWidth: { md: '600px' }, mt: 2 }}>
                    {
                        animatingWar &&
                        <Box
                            sx={{
                                width: 100,
                                height: 100,
                                marginTop: "-35px",
                                backgroundColor: "#FEE2E2",
                                borderRadius: "50%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                animation: "scaleUp 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) forwards"
                            }}
                        >
                            <svg
                                width="62"
                                height="62"
                                fill="none"
                                stroke="red"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ width: "62px", height: "62px", stroke: "red" }}
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M18.364 5.636a9 9 0 010 12.728m0 0l-2.829-2.829m2.829 2.829L21 21M15.536 8.464a5 5 0 010 7.072m0 0l-2.829-2.829m-4.243 2.829a4.978 4.978 0 01-1.414-2.83m-1.414 5.658a9 9 0 01-2.167-9.238m7.824 2.167a1 1 0 111.414 1.414m-1.414-1.414L3 3"
                                />
                            </svg>
                        </Box>



                    }

                    <Typography variant='landingSubTitle' sx={{ textAlign: 'center', lineHeight: '35px', fontWeight: "500",animation: "scaleUp 0.6s cubic-bezier(0.34, 1.56, 0.64, 1) forwards" }}>
                        You're offline<br />
                        <span style={{ fontSize: '16px', color: "#959393" }}>Please reconnect to the internet and refresh the page.</span>
                    </Typography>
                    {/* <Typography variant='landingSubTitle' sx={{ textAlign:'center',lineHeight:'35px'}}>Wi-Fi needs therapy...<br></br> too many issues<br></br>
                        
                    <span style={{fontSize:'18px'}}>Please reconnect to internet and Refresh the page </span>
                    </Typography> */}

                </Stack>
            </Box>
        </ModalComponent>
    )
}
