import { server } from "../utils/server"

const getFamilyMembers = async (id) => {
    return await server.get(`/familyMember/getFamilyMembers/${id}`).then(res=>{
        return res.data
    }).catch(err=>{
        console.log(err);        
        return err
    }
    )
}

const getDltWallet = async (id) => {
    return await server.get(`/familyMember/getDltWallet/${id}`).then(res=>{
        return res.data
    }).catch(err=>{ 
        console.log(err);
        return err
    }
)
}
const familyMemberServices = {
    getFamilyMembers,
    getDltWallet
}

export default familyMemberServices;