
import {
    Alert,
    Button,
    Card,
    CardContent,
    Divider,
    Stack,
    Typography,
    Box
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import userServices from "../../../../services/userServices";
import { useNavigate } from "react-router-dom";
import Label from "../../../../components/label";
import { ConfirmActionModal } from "../../../../components/modals/confirmActionModal";
import { useSnackbar } from "notistack";
import AddFamilyMember from "../../../../components/modals/addFamilyMember";
import FamilymemberDltWallet from "./FamilymemberDltWallet";

// import HufDltWallet from "./HufDltWallet";

function FamilyMemberProfile({ fmailyMemberDetail, url, getallFamilyMember }) {
    
    const user = useSelector(state => state.user.data);
   
      
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(0)
    const [actionModal, setActionModal] = useState(false);
    const [createFamilyMember, setCreateFamilyMember] = useState(false)
    const [familyMemberId,setFamilyMemberId]=useState("")
    const { enqueueSnackbar } = useSnackbar();

  
    const handleCreateFamilyMember = () => {
        setCreateFamilyMember(false)
        setCurrentStep(0)
        setFamilyMemberId("")
    }


    return (
        <Box sx={{display:"flex",flexDirection:"column",alignItems:"center"}} >
            <AddFamilyMember open={createFamilyMember} handleClose={handleCreateFamilyMember} steps={currentStep} familyMemberIdGet={familyMemberId} getallFamilyMember={getallFamilyMember}/>
                <Stack
                        width={{md:'100%',xs:'100%'}}
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        my={2}
                        // mx={{md:"auto",xs:0}}
                        px={{md:'40px',xs:'5px'}}
                      >
                        <Typography variant="mainCardTitle">
                        Family Members
                        </Typography>
                        
                
                        <Stack direction={'row'} columnGap={1}>
                         
                          <Stack direction="row" spacing={2}>
                            
                          </Stack>
                         
                          {fmailyMemberDetail.length!=2&&<Button
                            variant="contained"
                            color="primary"
                            size="small"
                            sx={{ padding: '10px 12px !important' }}
                            onClick={() => setCreateFamilyMember(true)}
                          >
                            Add Family member
                          </Button>}
                        </Stack>
                      </Stack>
           
            {fmailyMemberDetail?.map((row) => {
                return (
                    <Card
                    // my={2}
                    //     mx={{md:"auto",xs:0}}
                    //     px={{md:'40px',xs:'5px'}}
                        sx={{
                            margin: 2,
                            padding: 2,
                            // minWidth: '30%',
                            boxShadow: 1,
                            
                            // ...(row?.familyMember?.walletInfo &&{ 
                                display: "flex", width: "95%", justifyContent: 'space-between',
                                flexDirection:{md:"column",lg:"row",sm:"column",xs:"column"}
                            //  })
                        }}
                    >

                        
                        <CardContent sx={
                            // row.familyMember?.walletInfo ? 
                            { width: {md:'100%',xs:'100%',lg:"40%",sm:"100%"} } 
                            // : {}
                            }>
                            <Stack rowGap={2}>
                                <Stack direction={'row'} spacing={2} justifyContent={"space-between"} alignItems={'center'}>
                                    <Typography>
                                        {row?.familyMember?.status ?
                                            <Label sx={{ py: 2, px: 2, fontWeight: 600 }} variant="ghost" color={row?.familyMember?.status == 'REGISTERED' ? 'warning' : row?.familyMember?.status == 'REJECTED' ? 'error' : row?.familyMember?.status == 'APPROVED' ? 'success' : row?.familyMember?.status == 'KYC_APPROVED' ? 'success' : row?.familyMember?.status == 'KYC_COMPLETE' ? 'warning':""}>
                                                {row?.familyMember?.status === "KYC_APPROVED" ? "KYC Approved" :row?.familyMember?.status === "KYC_COMPLETE" ? "KYC Submitted & Approval Pending": row?.familyMember?.status}
                                            </Label> : "-"}
                                    </Typography>
                                    {row?.familyMember?.status !== "APPROVED" && row?.familyMember?.status !== "KYC_COMPLETE" && (
                                        <Stack direction={'row'} spacing={1}>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                onClick={() => {
                                                    setCreateFamilyMember(true);

                                                    // Check FamilyMember status and set current step accordingly
                                                    if (row?.familyMember?.status === "REGISTERED") {
                                                        setCurrentStep(1); // If status is REGISTER, set the step to 1
                                                    } else if (row?.familyMember?.status === "KYC_APPROVED") {
                                                        setCurrentStep(2); // If status is KYC_APPROVED, set the step to 2
                                                    }
                                                    else if (row?.familyMember?.status === "REJECTED") {
                                                        setCurrentStep(1); // If status is KYC_APPROVED, set the step to 2
                                                    }      
                                                    setFamilyMemberId(row?.familyMember?._id)
                                                }}
                                            >
                                                {row?.familyMember?.status === "KYC_APPROVED"? "Create DLT Account":row?.familyMember?.status==="REJECTED"?"Complete KYC":"Complete"}
                                            </Button>
                                        </Stack>
                                    )}
                                </Stack>
                                <Divider></Divider>
                                <Stack direction={'row'} alignItems={'center'} spacing={2} justifyContent={"space-between"}>
                                    <Typography variant="label">Username</Typography>
                                    <Typography variant="body2">{ row?.familyMember
                                            ?.legalName||row?.familyMember
                                            ?.username}</Typography>
                                </Stack>

                                <Stack direction={'row'} alignItems={'center'} spacing={2} justifyContent={"space-between"}>
                                    <Typography variant="label">Relation</Typography>
                                    <Typography variant="body2">{row?.relation}</Typography>
                                </Stack>
                                {row?.familyMember
                                    ?.isPanCard && <Stack direction={'row'} alignItems={'center'} spacing={2} justifyContent={"space-between"}>
                                        <Typography variant="label">PAN Number</Typography>
                                        <Typography variant="body2">{row?.familyMember
                                            ?.panCardNumber}</Typography>
                                    </Stack>}

                                {row?.familyMember
                                        ?.aadharNumber&&<Stack direction={'row'} alignItems={'center'} spacing={2} justifyContent={"space-between"}>
                                    <Typography variant="label">Aadhar Number</Typography>
                                    <Typography variant="body2">{row?.familyMember
                                        ?.aadharNumber}</Typography>
                                </Stack>}
                               

                                {/* blockchain acc */}
                            </Stack>

                        </CardContent>
                        
                        <>
                            <Divider sx={{ borderColor: "#00000017", borderWidth: "1px" }}></Divider>
                            
                            {
                                row.familyMember?.walletInfo&&row?.familyMember?.status !== "APPROVED" || row?.familyMember?.status !== "KYC_APPROVED" ?
                                    <FamilymemberDltWallet familyMemberId={row.familyMember._id} getallFamilyMember={getallFamilyMember}/>: (
                                    // <HufDltWallet hufAccountId={row?.hufAccountId} getHufDetails={()=>getHufDetails()} /> : (
                                        <Alert severity="error" variant="outlined" sx={{width:"50%",marginLeft:"10px"}}>
                                            {row?.familyMember?.status == "KYC_APPROVED" ?<Typography variant="body1" fontSize={14}>
                                                Please create a DLT account.
                                            </Typography>:
                                            <Typography variant="body1" fontSize={14}>
                                            Once your KYC is approved, you can create a DLT account.
                                        </Typography>}
                                        </Alert>
                                    )
                                    //HufDltWallet
                           
                        }
                        </>
                    
                    </Card>
                )

            })}

        </Box>)
}


export default FamilyMemberProfile
